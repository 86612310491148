<template>
  <div>
    <el-card class="box-card">
      <el-form
        :model="pcgsimg"
        ref="pcgsimgRef"
        label-width="100px"
      >
        <el-form-item label="PCGS编号" @blur="judge">
          <el-input v-model="pcgsimg.cert" style="width: 223px"></el-input>
        </el-form-item>

        <el-form-item label="图片">
          <el-upload
            ref="upload2"
            class="upload-demo"
            drag
            action="/api/files/uploadPcgsImg"
            :on-success="imgSuccess"
            :before-upload="beforeuploadImg"
          >
            <i class="el-icon-upload"></i>
  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过3M</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="judge()">验证</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add()">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserInput",
  data() {
    return {
      pcgsimg: {},
      rules: {
        name: [{ required: true, message: "请输入编号", trigger: "blur" }],
      },
    };
  },
  methods: {
    imgSuccess(res) {
      this.pcgsimg.img = res.msg;
    },
    reset() {
        this.$refs.upload2.clearFiles();
      this.pcgsimg = this.cate2 = JSON.parse(JSON.stringify({}));
    },
    add() {
      this.$refs.pcgsimgRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/user/upload", this.pcgsimg).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.reset();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    judge() {
      if (this.pcgsimg.cert == null) return;
      //发起网络请求
      request.get("/user/upload", {
          params: {
            cert: this.pcgsimg.cert,
          },
      }).then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: res.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    beforeuploadImg(file) {
      const isLt3M = file.size / 1024 / 1024 < 3;

      console.log('图片上传：'+isLt3M)
      if (!isLt3M) {
        this.$message({
          message: "广告图大小不能超过3M!请重新选择!",
          type: "warning",
        });
        return false; //必须加上return false; 才能阻止
      }
    }
  },
};
</script>

<style scoped>
</style>
