<template>
  <div>
    <el-card class="box-card">
      <el-form
        :model="template"
        :rules="rules"
        :inline="true"
      >
        <el-form-item label="系列" prop="cate1">
          <el-select
            v-model="template.cate1_id"
            placeholder="系列"
            @change="getCate2List"
          >
            <el-option
              v-for="item in cate1List"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品名" prop="cate2">
          <el-select v-model="template.cate2_id" placeholder="品名">
            <el-option
              v-for="item in cate2List"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
            <el-button type="success" @click="insertVisable = true"
              >新增</el-button
            >
          </el-form-item>
      </el-form>

      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="系列" prop="cate2.cate1.name"></el-table-column>
        <el-table-column label="品名" prop="cate2.name"></el-table-column>
        <el-table-column label="分值" prop="score"></el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-image :fit="fit" :src="scope.row.img"></el-image
          ></template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="primary" @click="preUpdate(scope.row)">修改</el-button>.
            <el-button type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加存量的弹出框 -->
      <el-dialog
        title="提示"
        :visible.sync="insertVisable"
        width="30%"
      >
        <el-form :model="template" :rules="rules" ref="update" label-width="100px">
          <el-form-item label="分值" prop="score">
          <el-select v-model="template.score" placeholder="请选择分值">
            <el-option label="45分" value="45"></el-option>
            <el-option label="40分" value="40"></el-option>
            <el-option label="68分" value="68"></el-option>
            <el-option label="67+分" value="67+"></el-option>
            <el-option label="67分" value="67"></el-option>
            <el-option label="66+分" value="66+"></el-option>
            <el-option label="66分" value="66"></el-option>
            <el-option label="65+分" value="65+"></el-option>
            <el-option label="65分" value="65"></el-option>
            <el-option label="64+分" value="64+"></el-option>
            <el-option label="64分" value="64"></el-option>
            <el-option label="63+分" value="63+"></el-option>
            <el-option label="63分" value="63"></el-option>
            <el-option label="62+分" value="62+"></el-option>
            <el-option label="62分" value="62"></el-option>
            <el-option label="61+分" value="61+"></el-option>
            <el-option label="61分" value="61"></el-option>
            <el-option label="60+分" value="60+"></el-option>
            <el-option label="60分" value="60"></el-option>
            <el-option label="58+分" value="58+"></el-option>
            <el-option label="58分" value="58"></el-option>
            <el-option label="55分" value="55"></el-option>
            <el-option label="53分" value="53"></el-option>
            <el-option label="50分" value="50"></el-option>
            <el-option label="35分" value="35"></el-option>
            <el-option label="30分" value="30"></el-option>
            <el-option label="91分" value="91"></el-option>
            <el-option label="92分" value="92"></el-option>
            <el-option label="93分" value="93"></el-option>
            <el-option label="94分" value="94"></el-option>
            <el-option label="95分" value="95"></el-option>
            <el-option label="96分" value="96"></el-option>
            <el-option label="97分" value="97"></el-option>
            <el-option label="98分" value="98"></el-option>
            <el-option label="UNC92分" value="UNC92"></el-option>
            <el-option label="UNC97分" value="UNC97"></el-option>
            <el-option label="UNC98分" value="UNC98"></el-option>
            <el-option label="sp40" value="sp40"></el-option>
            <el-option label="sp45" value="sp45"></el-option>
            <el-option label="sp50" value="sp50"></el-option>
            <el-option label="sp53" value="sp53"></el-option>
            <el-option label="sp55" value="sp55"></el-option>
            <el-option label="sp58" value="sp58"></el-option>
            <el-option label="sp58+" value="sp58+"></el-option>
            <el-option label="sp60" value="sp60"></el-option>
            <el-option label="sp60+" value="sp60+"></el-option>
            <el-option label="sp61" value="sp61"></el-option>
            <el-option label="sp61+" value="sp61+"></el-option>
            <el-option label="sp62" value="sp62"></el-option>
            <el-option label="sp62+" value="sp62+"></el-option>
            <el-option label="sp63" value="sp63"></el-option>
            <el-option label="sp63+" value="sp63+"></el-option>
            <el-option label="sp64" value="sp64"></el-option>
            <el-option label="sp64+" value="sp64+"></el-option>
            <el-option label="sp65" value="sp65"></el-option>
            <el-option label="sp65+" value="sp65+"></el-option>
            <el-option label="sp66" value="sp66"></el-option>
            <el-option label="sp66+" value="sp66+"></el-option>
            <el-option label="sp67" value="sp67"></el-option>
            <el-option label="sp67+" value="sp67+"></el-option>
            <el-option label="sp68" value="sp68"></el-option>
          </el-select>
        </el-form-item>
          <el-form-item label="图片上传">
            <el-upload
              ref="upload1"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess1"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="insertVisable = false">取 消</el-button>
          <el-button type="primary" @click="insert">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 修改的弹出框 -->
      <el-dialog
        title="提示"
        :visible.sync="updateVisable"
        width="30%"
      >
        <el-form :model="currentTemplate" :rules="rules" ref="update" label-width="100px">
          <el-form-item label="分值" prop="score">
          <el-select v-model="currentTemplate.score" placeholder="请选择分值">
            <el-option label="45分" value="45"></el-option>
            <el-option label="40分" value="40"></el-option>
            <el-option label="68分" value="68"></el-option>
            <el-option label="67+分" value="67+"></el-option>
            <el-option label="67分" value="67"></el-option>
            <el-option label="66+分" value="66+"></el-option>
            <el-option label="66分" value="66"></el-option>
            <el-option label="65+分" value="65+"></el-option>
            <el-option label="65分" value="65"></el-option>
            <el-option label="64+分" value="64+"></el-option>
            <el-option label="64分" value="64"></el-option>
            <el-option label="63+分" value="63+"></el-option>
            <el-option label="63分" value="63"></el-option>
            <el-option label="62+分" value="62+"></el-option>
            <el-option label="62分" value="62"></el-option>
            <el-option label="61+分" value="61+"></el-option>
            <el-option label="61分" value="61"></el-option>
            <el-option label="60+分" value="60+"></el-option>
            <el-option label="60分" value="60"></el-option>
            <el-option label="58+分" value="58+"></el-option>
            <el-option label="58分" value="58"></el-option>
            <el-option label="55分" value="55"></el-option>
            <el-option label="53分" value="53"></el-option>
            <el-option label="50分" value="50"></el-option>
            <el-option label="35分" value="35"></el-option>
            <el-option label="30分" value="30"></el-option>
            <el-option label="91分" value="91"></el-option>
            <el-option label="92分" value="92"></el-option>
            <el-option label="93分" value="93"></el-option>
            <el-option label="94分" value="94"></el-option>
            <el-option label="95分" value="95"></el-option>
            <el-option label="96分" value="96"></el-option>
            <el-option label="97分" value="97"></el-option>
            <el-option label="98分" value="98"></el-option>
            <el-option label="UNC92分" value="UNC92"></el-option>
            <el-option label="UNC97分" value="UNC97"></el-option>
            <el-option label="UNC98分" value="UNC98"></el-option>
            <el-option label="sp40" value="sp40"></el-option>
            <el-option label="sp45" value="sp45"></el-option>
            <el-option label="sp50" value="sp50"></el-option>
            <el-option label="sp53" value="sp53"></el-option>
            <el-option label="sp55" value="sp55"></el-option>
            <el-option label="sp58" value="sp58"></el-option>
            <el-option label="sp58+" value="sp58+"></el-option>
            <el-option label="sp60" value="sp60"></el-option>
            <el-option label="sp60+" value="sp60+"></el-option>
            <el-option label="sp61" value="sp61"></el-option>
            <el-option label="sp61+" value="sp61+"></el-option>
            <el-option label="sp62" value="sp62"></el-option>
            <el-option label="sp62+" value="sp62+"></el-option>
            <el-option label="sp63" value="sp63"></el-option>
            <el-option label="sp63+" value="sp63+"></el-option>
            <el-option label="sp64" value="sp64"></el-option>
            <el-option label="sp64+" value="sp64+"></el-option>
            <el-option label="sp65" value="sp65"></el-option>
            <el-option label="sp65+" value="sp65+"></el-option>
            <el-option label="sp66" value="sp66"></el-option>
            <el-option label="sp66+" value="sp66+"></el-option>
            <el-option label="sp67" value="sp67"></el-option>
            <el-option label="sp67+" value="sp67+"></el-option>
            <el-option label="sp68" value="sp68"></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="图片上传">
            <el-upload
              ref="upload2"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess2"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateVisable = false">取 消</el-button>
          <el-button type="primary" @click="update">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AdminScore",
  data() {
    return {
      template: {},
      currentTemplate: {},
      cate1List: [],
      cate2List: [],
      tableList: [],
      insertVisable: false,
      updateVisable: false,
      rules: {
        cate1: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        cate2: [
          { required: true, message: "请选择正确的品名", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请选择正确的分值", trigger: "blur" },
        ],
        equal: [
          { required: true, message: "请输入正确的数字", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCate1List();
  },
  methods: {
    getCate1List() {
      request.get("/common/getCate1List", {}).then((res) => {
        this.cate1List = res.data;
      });
    },
    getCate2List(cate1id) {
      request
        .get("/common/getCate2List", {
          params: {
            id: cate1id,
          },
        })
        .then((res) => {
          this.cate2List = res.data;
        });
    },
    imgSuccess1(res) {
      this.template.img = res.msg;
    },
    imgSuccess2(res) {
      this.currentTemplate.img = res.msg;
    },
    resetForm() {
      this.insertVisable = false
      this.updateVisable = false
      this.search()
    },
    preUpdate(row) {
      this.currentTemplate = JSON.parse(JSON.stringify(row));
      this.updateVisable = true
    },
    update() {
      if (this.currentTemplate.cate2_id == null || this.currentTemplate.score == null || this.currentTemplate.img == null) {
        this.$message({
              type: "error",
              message: '请勿提交空值！！！',
            });
          return
      }
      request.post("/admin/template/update", this.currentTemplate).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs.upload2.clearFiles();
            this.resetForm();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    insert() {
      if (this.template.cate2_id == null || this.template.score == null || this.template.img == null) {
        this.$message({
              type: "error",
              message: '请勿提交空值！！！',
            });
          return
      }
      request.post("/admin/template", this.template).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs.upload1.clearFiles();
            this.resetForm();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    search() {
      if (this.template.cate1_id == null || this.template.cate2_id == null) {
        this.$message({
              type: "error",
              message: '请选择系列和品名！',
            });
          return
      }
      request
        .get("/admin/template", {
          params: {
            cate2_id: this.template.cate2_id,
          },
        })
        .then((res) => {
          if (res.code == "0") {
            this.tableList = res.data;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },

    del(id) {
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/template/del/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.search()
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },


  },
};
</script>

<style scoped>

</style>
