<template>
  <div>
    <el-card class="box-card">
      <el-form
        :model="password"
        :rules="rules"
        ref="pasref"
        label-width="100px"
      >
        <el-form-item label="旧密码" prop="old">
          <el-input v-model="password.old" style="width: 223px"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new1">
          <el-input v-model="password.new1" style="width: 223px"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="new2">
          <el-input v-model="password.new2" style="width: 223px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sub()">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserSearch",
  data() {
    return {
      password: {},
      updateVisable: false,
      rules: {
        old: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        new1: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        new2: [{ required: true, message: "请输入确认密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    sub() {
      this.$refs.pasref.validate((valid) => {
        console.log(valid);
        if (!valid) return;
        //发起网络请求
        request.get("/user/info", {
            params:{
            old: this.password.old,
            new1: this.password.new1,
            new2: this.password.new2,
            }
        }).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.password = {}
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
