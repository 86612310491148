<template>
  <div id="top">
    <el-breadcrumb eparator-class="/">
      <el-breadcrumb-item>铜钱</el-breadcrumb-item>
      <el-breadcrumb-item>分类管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="分类管理" name="first">
          <el-row :gutter="0">
            <el-form :inline="true">
              <el-form-item>
                <el-cascader
                    v-model="parent_list"
                    :options="options"
                    :props="{ checkStrictly: true }"
                    @change="handleChange"
                  ></el-cascader>
              </el-form-item>
              <el-form-item>
                <el-button type="success" @click="addCategoryVisable = true"
                  >新增铜钱品名</el-button
                >
              </el-form-item>
            </el-form>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="一级目录" name="second">
          <el-form :inline="true">
            <el-form-item>
              <el-button type="success" @click="addCategoryVisable = true"
                >新增一级目录</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" border stripe>
        <el-table-column label="id" prop="id" width="50"></el-table-column>
        <el-table-column label="品名" prop="name" width="150"></el-table-column>
        <el-table-column label="缩略图" width="400">
          <template slot-scope="scope">
            <el-image :src="scope.row.img"></el-image
          ></template>
        </el-table-column>
        <el-table-column label="序号" prop="sort" width="80"></el-table-column>
        <el-table-column label="是否显示">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.show"
              @change="statusChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="warning" @click="showDialog(scope.row)"
              >修改</el-button
            >
            <el-button type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加品名的弹出框 -->
      <el-dialog title="提示" :visible.sync="addCategoryVisable" width="40%">
        <el-form
          :model="category"
          :rules="rules"
          ref="categoryRef"
          label-width="70px"
        >
          <el-form-item label="系列名称" prop="name">
            <el-input v-model="category.name"></el-input>
          </el-form-item>
          <el-form-item label="封面">
            <el-upload
              ref="upload1"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCategoryVisable = false">取 消</el-button>
          <el-button type="primary" @click="addCategory">提 交</el-button>
        </span>
      </el-dialog>
      <div
        style="
           {
            height: 20px;
            width: 100px;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
            position: fixed;
            top: 85%;
            right: 5%;
          }
        "
      >
        <a href="#top">返回顶部</a>
      </div>

      <!-- 修改品名的弹出框 -->
      <el-dialog title="提示" :visible.sync="updateVisable" width="80%">
        <el-form
          :model="category"
          :rules="rules"
          ref="updateRef"
          label-width="100px"
        >
          <el-form-item label="系列名称" prop="name">
            <el-input v-model="category.name"></el-input>
          </el-form-item>
          <el-form-item label="修改缩略图">
            <el-upload
              ref="upload2"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>

          <el-form-item label="排序" prop="sort">
            <el-input v-model="category.sort"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateVisable = false">取 消</el-button>
          <el-button type="primary" @click="update">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import E from "wangeditor";
import request from "@/utils/request";

let editor;
export default {
  name: "AdminCate",
  data() {
    return {
      cate1List: [],
      category: {},
      activeName: "first",
      parent_list: [],
      parent_id: 0,
      pagenum: 1,
      pagesize: 2,
      total: 0,
      tableData: [],
      addCategoryVisable: false,
      options:[],
      updateVisable: false,
      updateInfoVisable: false,
      cate2: {},
      temp:0,
      rules: {
        name: [
          { required: true, message: "请输入合理的名称", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入合理的序号", trigger: "blur" },
        ],
        nickname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCateList();
  },
  methods: {
    imgSuccess(res) {
      this.category.img = res.msg;
    },
    getCateList() {
      //发起请求
      request.get("/admin/copper/category/getAllCategory", {}).then((res) => {
        this.options = res.data;
      });
    },
    handleClick(tab, event) {
        if(this.activeName == "second") {
          this.temp = this.parent_id
          this.parent_id = 0
        }
        else this.parent_id = this.temp
        this.search()
      },

    //监听当前页码的改变
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getCate1List();
    },
    //监听页面大小的改变
    handleSizeChange(newSize) {
      this.pagesize = newSize;
      this.getCate1List();
    },
    showDialog(row) {
      console.log(row);
      this.category = JSON.parse(JSON.stringify(row)); //深拷贝
      this.category.parent = this.parent_id
      this.updateVisable = true;
    },
    search() {
      request
        .get("/admin/copper/category/getCategory", {
          params: {
            id: this.parent_id,
          },
        })
        .then((res) => {
          this.tableData = res.data;
        });
    },
    handleChange(value) {
      this.parent_id = value[value.length-1];
      this.search(value[value.length-1]);
    },
    addCategory() {
      this.$refs.categoryRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        this.category.parent = this.parent_id
        request.post("/admin/copper/category/addCategory", this.category).then((res) => {
          if (res.code == "0") {
            this.addCategoryVisable = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getCateList();
            this.search();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    update() {
      this.$refs.updateRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request
          .post("/admin/copper/category/updateCategory", this.category)
          .then((res) => {
            if (res.code == "0") {
              this.updateVisable = false;
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.$refs.upload2.clearFiles();
              this.search();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
      });
    },
    del(id) {
      console.log(id);
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request
            .delete("/admin/copper/category/delCategory/" + id, {})
            .then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.search();
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    statusChange(cate) {
      //请求，改变status的值
      console.log(cate.show);
      request
        .post("/admin/copper/category/updateShow", {
          id: cate.id,
          show: cate.show,
        })
        .then((res) => {});
    },
  },
};
</script>

<style scoped>
</style>
