<template>
  <div>
    <el-card class="box-card">
      <el-form
        :inline="true"
        :model="searchForm"
        class="top-from"
        size="mini"
        :rules="rules"
        ref="searchFormRef"
      >
        <el-form-item label="vip级别" prop="vip">
          <el-select v-model="searchForm.vip" placeholder="选择vip的级别">
            <el-option label="vip" value="1"></el-option>
            <el-option label="svip" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时长" prop="month">
          <el-select
            v-model="searchForm.month"
            placeholder="选择时长（单位：月）"
          >
            <el-option label="1个月" value="1"></el-option>
            <el-option label="3个月" value="3"></el-option>
            <el-option label="6个月" value="6"></el-option>
            <el-option label="12个月" value="12"></el-option>
            <el-option label="五年" value="60"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"
            ><i class="el-icon-search"></i> 查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="addCodeVisable = true"
            ><i class="el-icon-plus"></i> 新增</el-button
          >
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="优惠码" prop="code"></el-table-column>
        <el-table-column label="vip类型">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.vip == 1">普通会员</el-tag>
            <el-tag type="danger" v-else>高级会员</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="时长" prop="month"></el-table-column>
        <el-table-column label="生成时间" prop="entrytime"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.used == 0">未使用</el-tag>
            <el-tag type="info" v-else>已使用</el-tag>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加CDK的弹出框 -->
      <el-dialog title="提示" :visible.sync="addCodeVisable" width="390px">
        <el-form
          :rules="rules"
          ref="addFormRef"
          :model="addForm"
          label-width="90px"
        >
          <el-form-item label="vip级别" prop="vip">
            <el-select v-model="addForm.vip" placeholder="选择vip的级别">
              <el-option label="vip" value="1"></el-option>
              <el-option label="svip" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时长" prop="month">
            <el-select
              v-model="addForm.month"
              placeholder="选择时长（单位：月）"
            >
              <el-option label="1个月" value="1"></el-option>
              <el-option label="3个月" value="3"></el-option>
              <el-option label="6个月" value="6"></el-option>
              <el-option label="12个月" value="12"></el-option>
              <el-option label="五年" value="60"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生成数量" prop="count">
            <el-select v-model="addForm.count" placeholder="请选择数量">
              <el-option label="1" value="1"></el-option>
              <el-option label="5" value="5"></el-option>
              <el-option label="10" value="10"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCodeVisable = false">取 消</el-button>
          <el-button type="primary" @click="addCode">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "AdminCate",
  data() {
    return {
      searchForm: {
        vip: "",
        month: "",
      },
      addForm: {
        vip: "",
        month: "",
        count: 1,
      },
      addCodeVisable: false,
      tableData: [],
      rules: {
        vip: [{ required: true, message: "不要为空！", trigger: "blur" }],
        month: [{ required: true, message: "不要为空！", trigger: "blur" }],
        count: [{ required: true, message: "不要为空！", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    //查询方法
    search() {
      this.$refs.searchFormRef.validate((valid) => {
        if (!valid) return;
        request
          .get("/admin/code", {
            params: {
              month: this.searchForm.month,
              vip: this.searchForm.vip,
            },
          })
          .then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.tableData = res.data;
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
      });
    },
    addCode() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/code", this.addForm).then((res) => {
          if (res.code == "0") {
            this.addCodeVisable = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.searchForm.vip = this.addForm.vip;
            this.searchForm.month = this.addForm.month;
            this.search();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
