<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="选择直播间">
            <el-select v-model="host_id" placeholder="来源">
              <el-option
                v-for="item in hostList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="transdate"
              value-format="yyyy-MM-dd"
              style="width: 223px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getTableData">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="excel">下载EXCEL</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id" width="70"></el-table-column>
        <el-table-column label="系列" prop="cate2.cate1.name"></el-table-column>
        <el-table-column label="品名" prop="cate2.name"></el-table-column>
        <el-table-column label="分值" prop="score"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="编号" prop="cert"></el-table-column>
        <el-table-column label="来源" prop="host.name"></el-table-column>
        <el-table-column label="交易日期" prop="transdate"></el-table-column>
        <el-table-column label="输入时间" prop="entrytime"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AdminExcel",
  data() {
    return {
      hostList: [],
      tableList: [],
      host_id: "",
      transdate: "",
      fileName: '',
      rules: {
        host: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        transdate: [
          { required: true, message: "请选择正确的品名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getHostList();
  },
  methods: {
    getTableData() {
      request
        .get("/excel", {
          params: {
            host_id: this.host_id,
            transdate: this.transdate,
          },
        })
        .then((res) => {
          //结果集处理
          if (res.code == "0") {
            this.tableList = res.data.records;
            this.fileName = res.data.fileName
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    getHostList() {
      //发起Cate1请求
      request.get("/common/getHostList", {}).then((res) => {
        this.hostList = res.data;
      });
    },
    excel() {
      console.log(this.fileName);
        if(this.fileName == '') {
            this.$message({
              type: "error",
              message: '请先点击查询!!!',
            });
        }
        else {
            window.location.href = "/api/excel/getExcelFile?fileName=" + this.fileName
        }
    },
  },
};
</script>

<style scoped>
</style>
