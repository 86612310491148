<template>
  <div>
    <el-card class="box-card">
      <el-form
        :inline="true"
        :model="searchForm"
        class="top-from"
        size="mini"
        :rules="rules"
        ref="searchFormRef"
      >
        <el-form-item label="查询用户" prop="value">
          <el-input
            v-model="searchForm.value"
            placeholder="请输入搜索的用户微信名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"
            ><i class="el-icon-search"></i>查 询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border stripe>
        <el-table-column label="id" prop="id" width="100px"></el-table-column>
        <el-table-column label="用户名" prop="nickname"></el-table-column>
        <el-table-column label="头像" width="120px">
          <template slot-scope="scope">
            <el-image :fit="fit" :src="scope.row.headimgurl"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="vip类型">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.vip == 0">普通用户</el-tag>
            <el-tag type="warning" v-else-if="scope.row.vip == 1">会 员</el-tag>
            <el-tag type="danger" v-else>超级会员</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="过期时间" prop="outtime"></el-table-column>
        <el-table-column label="最近使用时间" prop="usetime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="getOrder(scope.row)">用户订单</el-button>
            <el-button type="warning" @click="getWxUser(scope.row)">续费会员</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 订单信息的弹出框 -->
      <el-dialog title="用户订单" :visible.sync="ordersVisable" width="70%">
        <el-table :data="orderData" border stripe>
          <el-table-column label="标题" prop="title" width="100px"></el-table-column>
          <el-table-column label="订单号" prop="orderNo"></el-table-column>
          <el-table-column label="购买时长">
            <template slot-scope="scope">{{scope.row.month}} 个月</template>
          </el-table-column>
          <el-table-column label="订单金额">
            <template slot-scope="scope">{{scope.row.totalFee}} 元</template>
          </el-table-column>
          <el-table-column label="时间" prop="entrytime"></el-table-column>
          <el-table-column label="vip类型">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.status == false"
                >未支付</el-tag
              >
              <el-tag type="success" v-else>已支付</el-tag>
            </template>
          </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="ordersVisable = false">退出</el-button>
          </span>
      </el-dialog>

      <!-- 续费用户VIP -->
      <el-dialog title="续费VIP" :visible.sync="setVipVisable" width="390px">
        <el-form
          :rules="rules"
          ref="setVipFormRef"
          :model="setVipForm"
          label-width="90px"
        >
          <el-form-item label="vip级别" prop="vip">
            <el-select v-model="setVipForm.vip" placeholder="选择vip的级别">
              <el-option label="vip" value="1"></el-option>
              <el-option label="svip" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时长" prop="month">
            <el-select
              v-model="setVipForm.month"
              placeholder="选择时长（单位：月）"
            >
              <el-option label="1个月" value="1"></el-option>
              <el-option label="3个月" value="3"></el-option>
              <el-option label="6个月" value="6"></el-option>
              <el-option label="一年" value="12"></el-option>
              <el-option label="两年" value="24"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setVipVisable = false">取 消</el-button>
          <el-button type="primary" @click="setVip">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "AdminVip",
  data() {
    return {
      searchForm: {
        value: "",
      },
      setVipForm: {
        vip: "",
        month: "",
        openid: ""
      },
      ordersVisable: false,
      setVipVisable: false,
      tableData: [],
      orderData: [],
      rules: {
        value: [{ required: true, message: "不要为空！", trigger: "blur" }],
        month: [{ required: true, message: "不要为空！", trigger: "blur" }],
      }
    };
  },
  created() {},
  methods: {
    //查询方法
    search() {
      this.$refs.searchFormRef.validate((valid) => {
        if (!valid) return;
        request
          .get("/admin/vip", {
            params: {
              value: this.searchForm.value,
            },
          })
          .then((res) => {
            if (res.code == "0") {
              this.tableData = res.data;
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
      });
    },
    getOrder(row) {
      request
        .get("/admin/vip/getOrderByOpenId", {
          params: {
            openid: row.openid,
          },
        })
        .then((res) => {
          if (res.code == "0") {
            this.orderData = res.data;
            this.ordersVisable = true;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    getWxUser(row) {
      this.setVipForm.openid = row.openid
      this.setVipVisable = true
    },
    setVip() {
      this.$refs.setVipFormRef.validate((valid) => {
        if (!valid) return;
        request
          .post("/admin/vip/setVip", this.setVipForm)
          .then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: '提交成功！',
              });
              this.setVipVisable = false
              this.search()
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
      });
    },
  },
};
</script>

<style scoped>
</style>
