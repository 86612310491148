<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0" style="margin-bottom: 10px">
        <el-col :span="4">
          <el-button type="primary" @click="addCate1Visable = true"
            >添加系列</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="系列名称" prop="name"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="warning" @click="del(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagenum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!-- 添加系列的弹出框 -->
      <el-dialog title="提示" :visible.sync="addCate1Visable" width="50%">
        <el-form
          :model="addCate1Form"
          :rules="formRules"
          ref="addCate1FormRef"
          label-width="100px"
        >
          <el-form-item label="系列名称" prop="name">
            <el-input v-model="addCate1Form.name"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCate1Visable = false">取 消</el-button>
          <el-button type="primary" @click="addCate1">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AdminCate",
  data() {
    return {
      cate1List: [],
      cate1: 0,
      pagenum: 1,
      pagesize: 2,
      total: 0,
      tableList: [],
      addCate1Visable: false,
      addForm: {},
      addCate1Form: {},
      addCate2Form: {},
      formRules: {
        name: [
          { required: true, message: "请输入系列", trigger: "blur" },
          { min: 1, max: 20, message: "长度在2到20之间", trigger: "blur" },
        ],
        nickname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      //发起请求
      request.get("/common/getCate1List", {}).then((res) => {
        this.tableList = res.data;
      });
    },
    //监听当前页码的改变
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getCate1List();
    },
    //监听页面大小的改变
    handleSizeChange(newSize) {
      this.pagesize = newSize;
      this.getCate1List();
    },
    addCate1() {
      this.$refs.addCate1FormRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/cate/addCate1", this.addCate1Form).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getTableData();
            this.addCate1Form = {}
            this.addCate1Visable = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    //删除
    del(id) {
      console.log(id);
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/cate/delCate1/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style scoped>
</style>
