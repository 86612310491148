<template>
  <div>
    <div class="left">
      <div class="intep">裁剪图片******************</div>
      <div>
        <div class="image-copper">
          <VueCropper
            ref="cropper1"
            :img="option.img1"
            :info="true"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            @realTime="realTime1"
          ></VueCropper>
        </div>
        <div class="image-copper">
          <VueCropper
            ref="cropper2"
            :img="option.img2"
            :info="true"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            @realTime="realTime2"
          ></VueCropper>
        </div>
      </div>
      <div class="intep">预览结果*********************</div>
      <div>
        <div
          class="previews"
          :style="{
            'border-radius': '50%',
            width: previews1.w + 'px',
            height: previews1.h + 'px',
            overflow: 'hidden',
            margin: '5px',
          }"
        >
          <div :style="previews1.div">
            <img :src="previews1.url" :style="previews1.img" />
          </div>
        </div>
        <div
          class="previews"
          :style="{
            'border-radius': '50%',
            width: previews2.w + 'px',
            height: previews2.h + 'px',
            overflow: 'hidden',
            margin: '5px',
          }"
        >
          <div :style="previews2.div">
            <img :src="previews2.url" :style="previews2.img" />
          </div>
        </div>
      </div>
      <el-button
        type="success"
        @click="imgStart()"
        style="display: block; margin: 0 auto"
        >确认裁剪</el-button
      >
    </div>

    <div class="right">
      <div class="right-top">
        <div class="right-cankao">
          <img :src="option.img3" width="430px" height="600px" />
        </div>
        <div class="right-form">
          <el-form
            :model="record"
            :rules="rules"
            ref="recordRef"
            label-width="100px"
          >
            <el-form-item label="系列" prop="cate1_id">
              <el-select
                v-model="record.cate1_id"
                placeholder="系列"
                @change="getCate2List"
              >
                <el-option
                  v-for="item in cate1List"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品名" prop="cate2_id">
              <el-select v-model="record.cate2_id" placeholder="品名">
                <el-option
                  v-for="item in cate2List"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="编号" prop="cert">
              <el-input v-model="record.cert" style="width: 223px"></el-input>
            </el-form-item>
            <el-form-item label="成交价" prop="price">
              <el-input v-model="record.price" style="width: 223px"></el-input>
            </el-form-item>
            <el-form-item label="买家">
              <el-input v-model="record.buyer" style="width: 223px"></el-input>
            </el-form-item>
            <el-form-item label="数据来源" prop="host_id">
              <el-select v-model="record.host_id" placeholder="请选择数据来源">
                <el-option
                  v-for="item in hostList"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="日期">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="record.transdate"
                value-format="yyyy-MM-dd"
                style="width: 223px"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "Test",
  data() {
    return {
      option: {
        img1: "https://imgali.huaxiaguquan.com/pic/2023/0111/599183495434247701w1600h1597_450_.jpg", // 裁剪图片的地址
        img2: "https://imgali.huaxiaguquan.com/pic/2023/0111/599183495434247703w1600h1600_450_.jpg", // 裁剪图片的地址
        img3: "https://imgali.huaxiaguquan.com/pic/pj/GB/1116377055/45fcbc51f3bc459e9d3a5d7dc2e52f71_450_.jpg",
        info: false, // 裁剪框的大小信息(即是否显示裁剪框的宽高信息)
        outputSize: 1, // 裁剪生成图片的质量(0.1~1之间)
        outputType: "jpeg", // 裁剪生成图片的格式(jpg(jpg 需要传入jpeg))
        canScale: false, // 图片是否允许滚轮缩放(这个属性貌似没得用，不管设置true，false都可以滚轮缩放)
        autoCrop: true, // 是否默认生成截图框
        // 注：这里需要注意，如果是裁剪成圆形图片，那么截图框的宽高就是必须设置的，且最好宽高一样
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例(这是比例，按两个值的比值大小进行截图框的宽高的设置，[1,1]和[100,100]是一样的)
        full: true, // 是否输出原图比例的截图
        canMove: false, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      base64Data: {
        dataURL: "", // 用url方式表示的base64图片数据
        type: "image/jpeg", //文件类型
      },
      previews1: {},
      previews2: {},
      imgURL: "",
    };
  },
  created() {
    this.$nextTick(() => {
      this.editCropper();
    });
  },
  methods: {
    realTime1(data) {
      this.previews1 = data;
      //console.log(data)
    },
    realTime2(data) {
      this.previews2 = data;
      //console.log(data)
    },
    editCropper() {
      let _this = this;
      // 设置头像base64
      // 其中this.avatar为当前头像
      this.setAvatarBase64(this.option.img1, (base64) => {
        _this.option.img1 = base64;
      });
      this.setAvatarBase64(this.option.img2, (base64) => {
        _this.option.img2 = base64;
      });
    },
    // 设置头像base64
    setAvatarBase64(src, callback) {
      let _this = this;
      let image = new Image();
      // 处理缓存
      image.src = src + "?v=" + Math.random();
      // 支持跨域图片
      image.crossOrigin = "*";
      image.onload = function () {
        let base64 = _this.imageBase64(image);
        callback && callback(base64);
      };
    },
    //将图片转换成base64
    imageBase64(image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // // 可选其他值 image/jpeg
      return canvas.toDataURL("image/jpeg");
    },

    imgStart() {
      var data = new FormData();
      this.$refs.cropper1.getCropBlob((blob) => {
        data.append("file1", blob, "1.jpeg");
        this.$refs.cropper2.getCropBlob((blob) => {
          data.append("file2", blob, "2.jpeg");
          request
            .post("/files/uploadCopperImage", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
    },
    circle(obj64) {
      let img = new Image();
      img.src = obj64;
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        let r = img.width / 2;
        ctx.save(); // 保存当前canvas的状态
        ctx.arc(r, r, r, 0, Math.PI * 2, false); // 创建弧/曲线(用于创建圆形或部分圆)
        ctx.clip();
        ctx.drawImage(img, 0, 0);
        ctx.restore(); // 返回之前保存过的路径状态和属性，恢复状态
      };
    },
  },
};
</script>

<style scoped>
.intep {
  font-size: 20px;
  font-weight: 400;
  color: #1f2f3d;
  margin: 5px 0px 5px 3px;
}
.image-copper {
  display: inline-block;
  height: 250px;
  width: 250px;
}
.previews {
  display: inline-block;
}
/deep/ .cropper-view-box {
  border-radius: 50%;
}

.left {
  display: inline-block;
  width: 50%;
}

.right {
  display: inline-block;
  width: 50%;
}
</style>
