<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0" style="margin-bottom: 10px">
        <el-col :span="4">
          <el-button type="primary" @click="addHostVisable = true"
            >添加数据来源</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="显示来源" prop="source"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="showUpdateDig(scope.row)"
              >修改</el-button
            >
            <el-button type="warning" @click="del(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加系列的弹出框 -->
      <el-dialog title="提示" :visible.sync="addHostVisable" width="50%">
        <el-form
          :model="addHostForm"
          :rules="rules"
          ref="addHostFormRef"
          label-width="100px"
        >
          <el-form-item label="系列名称" prop="name">
            <el-input v-model="addHostForm.name"></el-input>
          </el-form-item>
          <el-form-item label="显示来源" prop="name">
            <el-input v-model="addHostForm.source"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addHostVisable = false">取 消</el-button>
          <el-button type="primary" @click="addHost">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 修改系列的弹出框 -->
      <el-dialog title="提示" :visible.sync="updateHostVisable" width="50%">
        <el-form
          :model="updateHostForm"
          :rules="rules"
          ref="updateHostFormRef"
          label-width="100px"
        >
          <el-form-item label="系列名称" prop="name">
            <el-input v-model="updateHostForm.name"></el-input>
          </el-form-item>
          <el-form-item label="显示来源" prop="name">
            <el-input v-model="updateHostForm.source"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateHostVisable = false">取 消</el-button>
          <el-button type="primary" @click="updateHost">提 交</el-button>
        </span>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AdminHost",
  data() {
    return {
      hostList: [],
      pagenum: 1,
      pagesize: 2,
      total: 0,
      tableList: [],
      addHostVisable: false,
      updateHostVisable: false,
      addForm: {},
      addHostForm: {},
      updateHostForm: {},
      formRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, max: 30, message: "长度在2到30之间", trigger: "blur" },
        ],
        source: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, max: 30, message: "长度在2到30之间", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getTableData();
  },
  methods: {

    getTableData() {
      //发起请求
      request.get("/admin/host", {}).then((res) => {
        this.tableList = res.data;
      });
    },
    addHost() {
      this.$refs.addHostFormRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/host", this.addHostForm).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getTableData();
            this.addHostForm = {}
            this.addHostVisable = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    
    showUpdateDig(hostObj) {
      //发起请求
      this.updateHostForm = hostObj
      this.updateHostVisable = true
    },

    /*
      修改来源
    */
    updateHost() {
      this.$refs.updateHostFormRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.put("/admin/host", this.updateHostForm).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getTableData();
            this.updateHostForm = {}
            this.updateHostVisable = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },

    //删除
    del(id) {
      console.log(id);
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/host" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style scoped>
</style>
