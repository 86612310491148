<template>
  <div id="copperRecord">
    <el-card>
      
      <el-form :inline="true">
          <el-form-item label="选择类别">
            <el-cascader
              v-model="parent_list"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
            :show-all-levels="false"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="分值" prop="price">
            <el-select v-model="score" placeholder="请选择分值">
              <el-option label="裸币" value="裸币"></el-option>
              <el-option label="70分" value="70"></el-option>
              <el-option label="72分" value="72"></el-option>
              <el-option label="75分" value="75"></el-option>
              <el-option label="78分" value="78"></el-option>
              <el-option label="80分" value="80"></el-option>
              <el-option label="82分" value="82"></el-option>
              <el-option label="84分" value="84"></el-option>
              <el-option label="85分" value="85"></el-option>
              <el-option label="86分" value="86"></el-option>
              <el-option label="88分" value="88"></el-option>
              <el-option label="90分" value="90"></el-option>
              <el-option label="92分" value="92"></el-option>
              <el-option label="95分" value="95"></el-option>
              <el-option label="98分" value="98"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getTableData">查询</el-button>
          </el-form-item>
        </el-form>

      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="分类" prop="category.name"></el-table-column>
        <el-table-column label="分值" prop="score"></el-table-column>
        <el-table-column label="规格" prop="shape"></el-table-column>
        <el-table-column label="评级公司" prop="corporation"></el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="编号" prop="cert"></el-table-column>
        <el-table-column label="来源" prop="host.name"></el-table-column>
        <el-table-column label="交易日期" prop="transdate"></el-table-column>
        <el-table-column label="输入时间" prop="entrytime"></el-table-column>
        <el-table-column label="操作员" prop="user.nickname"></el-table-column>
        <el-table-column label="是否精品" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.good == true">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button type="info" @click="showImg(scope.row.cert)" plain size="medium"
              >图片</el-button
            >
            <el-button type="success" @click="god(scope.row.id)" plain size="medium"
              >精品</el-button
            >
            <el-button type="danger" @click="del(scope.row.id)" plain size="medium"><i class="el-icon-delete"></i></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handlePageChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <el-dialog title="图片查看" :visible.sync="showImgVisable" width="40%">
        <el-image :fit="fit" :src="imgurl"></el-image>
        <el-upload
              ref="uploader"
              class="upload-demo"
              drag
              action="/api/files/uploadPcgsImg"
              :on-success="imgSuccess"
              :before-upload="beforeuploadImg"

            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传jpg/png文件，且不超过3M
              </div>
            </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateImg" type="primary">提交</el-button>
          <el-button @click="showImgVisable = false">关闭</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserSearch",
  data() {
    return {
      record: {},
      score: "",
      tableList: [],
      parent_list: [],
      options:[],
      currentPage: 1,
      parent_id: 0,
      total: 1,
      imgurl: "",
      cert: "",
      img: "",
      showImgVisable: false
    };
  },
  created() {
    this.getCateList();
  },
  methods: {
    getCateList() {
      //发起初始化分类请求
      request.get("/admin/copper/category/getAllCategory", {}).then((res) => {
        this.options = res.data;
      });
    },
    //分页改变
    handlePageChange(val) {
      this.getTableData();
    },
    getTableData() {
      if(this.parent_id == 0) return
      request
        .get("/admin/copper/record/search", {
          params: {
            cate2_id: this.parent_id,
            score: this.score,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.tableList = res.data.list;
          this.total = res.data.total;
        });
    },
    handleChange(value) {
      this.parent_id = value[value.length-1];
    },
    showImg(cert){
      this.cert = cert
      request
        .get("/admin/copper/img", {
          params: {
            cert: cert,
          },
        })
        .then((res) => {
          if (res.code == "0") {
            this.imgurl = '/api/files/getPcgsImg?fileName=' + res.data.img
            this.showImgVisable = true
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    updateImg() {
      let cert = this.cert
      request.post("/admin/copper/img", {
        cert: cert,
        img: this.img
      }).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs.uploader.clearFiles();
            this.showImgVisable = false
            this.getTableData();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    imgSuccess(res) {
      this.img = res.msg;
    },
    beforeuploadImg(file) {
      const isLt3M = file.size / 1024 / 1024 < 5;

      console.log("图片上传：" + isLt3M);
      if (!isLt3M) {
        this.$message({
          message: "图片大小不能超过5M!请重新选择!",
          type: "warning",
        });
        return false; //必须加上return false; 才能阻止
      }
    },
    //删除
    god(id) {
      this.$confirm("是否设为精品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/copper/record/good/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    //删除
    del(id) {
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/copper/record/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style scoped>
</style>
