<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0">
        <el-form :inline="true">
          <el-form-item label="系列">
            <el-select v-model="cate2.cate1_id" placeholder="请选择系列">
              <el-option
                v-for="item in cate1List"
                v-bind:key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
            <el-button type="success" @click="addCate2Visable = true"
              >新增品名</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <el-table :data="tableData" border stripe>
        <el-table-column label="id" prop="id" width="50"></el-table-column>
        <el-table-column label="品名" prop="name" width="150"></el-table-column>
        <el-table-column label="年份" prop="year" width="60"></el-table-column>
        <el-table-column label="面值" prop="denomination" width="130"></el-table-column>
        <el-table-column label="版别" prop="edition" width="130"></el-table-column>
        <el-table-column label="地区" prop="region" width="130"></el-table-column>
        <el-table-column label="PCGS编号" prop="pcgsid" width="130"></el-table-column>
        <el-table-column label="缩略图">
          <template slot-scope="scope">
            <el-image :fit="fit" :src="scope.row.img"></el-image
          ></template>
        </el-table-column>
        <el-table-column label="序号" prop="sort" width="80"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="warning" @click="showDialog(scope.row)"
              >修改</el-button
            >
            <el-button type="primary" @click="showDialogInfo(scope.row)"
              >修改信息</el-button
            >
            <el-button type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加品名的弹出框 -->
      <el-dialog title="提示" :visible.sync="addCate2Visable" width="40%">
        <el-form
          :model="cate2"
          :rules="rules"
          ref="cate2Ref"
          label-width="70px"
        >
          <el-form-item label="系列名称" prop="name">
            <el-input v-model="cate2.name"></el-input>
          </el-form-item>
          <el-form-item label="封面">
            <el-upload
              ref="upload1"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addCate2">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 修改品名的弹出框 -->
      <el-dialog title="提示" :visible.sync="updateVisable" width="80%">
        <el-form
          :model="cate2"
          :rules="rules"
          ref="updateRef"
          label-width="100px"
        >
          <el-form-item label="系列名称" prop="name">
            <el-input v-model="cate2.name"></el-input>
          </el-form-item>
          <el-form-item label="修改缩略图">
            <el-upload
              ref="upload2"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>

          <el-form-item label="排序" prop="sort">
            <el-input v-model="cate2.sort"></el-input>
          </el-form-item>

          <el-form-item label="币种介绍">
            <div id="editor"></div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">          
          <el-button @click="updateVisable = false">取 消</el-button>
          <el-button type="primary" @click="update">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 修改品名详细信息的弹出框 -->
      <el-dialog title="提示" :visible.sync="updateInfoVisable" width="80%">
        <el-form
          :model="cate2"
          :rules="rules"
          ref="updateInfoRef"
          label-width="100px"
        >
          <el-form-item label="年份" prop="name">
            <el-input v-model="cate2.year"></el-input>
          </el-form-item>
          <el-form-item label="面值" prop="name">
            <el-input v-model="cate2.denomination"></el-input>
          </el-form-item>
          <el-form-item label="版别" prop="name">
            <el-input v-model="cate2.edition"></el-input>
          </el-form-item>
          <el-form-item label="地区" prop="name">
            <el-input v-model="cate2.region"></el-input>
          </el-form-item>
          <el-form-item label="PCGS编号" prop="name">
            <el-input v-model="cate2.pcgsid"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateInfoVisable = false">取 消</el-button>
          <el-button type="primary" @click="updateInfo">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import E from "wangeditor";
import request from "@/utils/request";

let editor;
export default {
  name: "AdminCate",
  data() {
    return {
      cate1List: [],
      pagenum: 1,
      pagesize: 2,
      total: 0,
      tableData: [],
      addCate2Visable: false,
      updateVisable: false,
      updateInfoVisable: false,
      cate2: {},
      rules: {
        name: [
          { required: true, message: "请输入合理的名称", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入合理的序号", trigger: "blur" },
        ],
        nickname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCateList();
  },
  methods: {
    imgSuccess(res) {
      this.cate2.img = res.msg;
    },
    getCateList() {
      //发起请求
      request.get("/common/getCate1List", {}).then((res) => {
        this.cate1List = res.data;
      });
    },
    //监听当前页码的改变
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getCate1List();
    },
    //监听页面大小的改变
    handleSizeChange(newSize) {
      this.pagesize = newSize;
      this.getCate1List();
    },
    showDialogInfo(row) {
      console.log(row);
      this.cate2 = JSON.parse(JSON.stringify(row)); //深拷贝
      this.cate2.cate1_id = row.cate1.id;
      this.updateInfoVisable = true;
    },

    showDialog(row) {
      console.log(row);
      this.cate2 = JSON.parse(JSON.stringify(row)); //深拷贝
      this.cate2.cate1_id = row.cate1.id;
      this.updateVisable = true;
      this.$nextTick(() => {
        if (editor == null) {
          editor = new E("#editor");
          // 配置 server 接口地址
          editor.config.uploadImgServer =
            "http://82.156.173.117/api/files/editorUpload";
          editor.config.uploadFileName = "file";
          editor.create();
        }
        editor.txt.clear();
        editor.txt.html(row.description);
      });
    },
    search() {
      console.log(this.cate2.cate1_id);
      request
        .get("/common/getCate2List", {
          params: {
            id: this.cate2.cate1_id,
          },
        })
        .then((res) => {
          this.tableData = res.data;
        });
    },
    addCate2() {
      this.$refs.cate2Ref.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/cate/addCate2", this.cate2).then((res) => {
          if (res.code == "0") {
            this.addCate2Visable = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.search();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    update() {
      this.cate2.description = editor.txt.html();
      this.$refs.updateRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/cate/updateCate2", this.cate2).then((res) => {
          if (res.code == "0") {
            this.updateVisable = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs.upload2.clearFiles();
            this.search();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    updateInfo() {
      this.$refs.updateInfoRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/cate/updateCate2Info", this.cate2).then((res) => {
          if (res.code == "0") {
            this.updateInfoVisable = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.search();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    del(id) {
      console.log(id);
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/cate/delCate2/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style scoped>
</style>
