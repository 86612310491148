<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0">
        <el-col :span="4">
          <el-button type="primary" @click="dialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="姓名" prop="nickname"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.active"
              @change="statusChange(scope.row)"
              :disabled="scope.row.auth > 4"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240px">
          <template slot-scope="scope">
            <el-button type="warning" @click="resetPassword(scope.row.id)"
              >重置密码</el-button
            >
            <el-button type="success" @click="stat(scope.row.id)"
              >统计报表</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagenum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!-- 添加用户的弹出框 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
        <el-form
          :model="addForm"
          :rules="addFormRules"
          ref="addFormRef"
          label-width="70px"
        >
          <el-form-item label="账户" prop="username">
            <el-input v-model="addForm.username"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="nickname">
            <el-input v-model="addForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="addForm.phone"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 用户数据统计的弹出框 -->
      <el-dialog title="提示" :visible.sync="stasVisable" width="40%">
        <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="银元统计" name="pcgs">
    </el-tab-pane>
    <el-tab-pane label="铜钱统计" name="copper"></el-tab-pane>
  </el-tabs>
        <el-tag>输入记录数量</el-tag>
        <el-table :data="statTableList" border stripe style="width: 100%">
          <el-table-column prop="name" label="月份"> </el-table-column>
          <el-table-column prop="cnt" label="输入数量"> </el-table-column>
        </el-table>
        <el-tag>图片录入数量</el-tag>
        <el-table :data="statImgTableList" border stripe style="width: 100%">
          <el-table-column prop="name" label="月份"> </el-table-column>
          <el-table-column prop="cnt" label="输入数量"> </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="stasVisable = false">关 闭</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AdminUser",
  data() {
    var checkPhone = (rule, value, cb) => {
      //验证手机号
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (myreg.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的手机号"));
    };

    return {
      pagenum: 1,
      pagesize: 2,
      total: 0,
      tableList: [],
      dialogVisible: false,
      stasVisable: false,
      statTableList: [],
      statImgTableList: [],
      statistic: {},
      addForm: {},
      activeName: "pcgs",
      addFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, max: 18, message: "长度在3到18之间", trigger: "blur" },
        ],
        nickname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          { min: 0, max: 18, message: "长度在2到5之间", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    getUserList() {
      //发起请求
      request.get("/admin/user", {}).then((res) => {
        this.tableList = res.data;
      });
    },
    //监听当前页码的改变
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getUserList();
    },
    //监听页面大小的改变
    handleSizeChange(newSize) {
      this.pagesize = newSize;
      this.getUserList();
    },
    handleClick(tab, event) {
      console.log(this.activeName)
        if(this.activeName == "pcgs") {
          this.statTableList = this.statistic.pcgsRecord;
          this.statImgTableList = this.statistic.pcgsImg;
        }
        else {
          this.statTableList = this.statistic.copperRecord;
          this.statImgTableList = this.statistic.copperImg;
        }
      },
    stat(id) {
      this.activeName = "pcgs";
      request
        .get("/admin/stat", {
          params: {
            user_id: id,
          },
        })
        .then((res) => {
          this.statistic.pcgsRecord = res.data.pcgsRecord
          this.statistic.pcgsImg = res.data.pcgsImg
          this.statistic.copperRecord = res.data.copperRecord
          this.statistic.copperImg = res.data.copperImg
          this.statTableList = res.data.pcgsRecord;
          this.statImgTableList = res.data.pcgsImg;
          this.stasVisable = true;
        });
    },
    statusChange(user) {
      //请求，改变status的值
      console.log(user.active);
      request
        .post("/admin/user/updateActive", {
          id: user.id,
          active: user.active,
        })
        .then((res) => {});
    },
    addUser() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/user", this.addForm).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getUserList();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    //重置密码
    resetPassword(id) {
      this.$confirm("是否重置该用户密码为：123456 ", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request
            .get("/admin/user/resetPassword", {
              params: {
                id: id,
              },
            })
            .then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            });
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style scoped>
</style>
