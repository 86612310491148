<template>
  <div>
    <el-card class="box-card">
      
      <el-row :gutter="0">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="系列">
            <el-select
              v-model="price.cate1_id"
              placeholder="系列"
              @change="getCate2List"
            >
              <el-option
                v-for="item in cate1List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品名">
            <el-select v-model="price.cate2_id" placeholder="品名">
              <el-option
                v-for="item in cate2List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分值">
            <el-select v-model="price.score" placeholder="请选择分值">
              <el-option label="45分" value="45"></el-option>
            <el-option label="40分" value="40"></el-option>
            <el-option label="68分" value="68"></el-option>
            <el-option label="67+分" value="67+"></el-option>
            <el-option label="67分" value="67"></el-option>
            <el-option label="66+分" value="66+"></el-option>
            <el-option label="66分" value="66"></el-option>
            <el-option label="65+分" value="65+"></el-option>
            <el-option label="65分" value="65"></el-option>
            <el-option label="64+分" value="64+"></el-option>
            <el-option label="64分" value="64"></el-option>
            <el-option label="63+分" value="63+"></el-option>
            <el-option label="63分" value="63"></el-option>
            <el-option label="62+分" value="62+"></el-option>
            <el-option label="62分" value="62"></el-option>
            <el-option label="61+分" value="61+"></el-option>
            <el-option label="61分" value="61"></el-option>
            <el-option label="60+分" value="60+"></el-option>
            <el-option label="60分" value="60"></el-option>
            <el-option label="58+分" value="58+"></el-option>
            <el-option label="58分" value="58"></el-option>
            <el-option label="55分" value="55"></el-option>
            <el-option label="53分" value="53"></el-option>
            <el-option label="50分" value="50"></el-option>
            <el-option label="35分" value="35"></el-option>
            <el-option label="30分" value="30"></el-option>
            <el-option label="92分" value="92"></el-option>
            <el-option label="97分" value="97"></el-option>
            <el-option label="98分" value="98"></el-option>
            <el-option label="sp40" value="sp40"></el-option>
            <el-option label="sp45" value="sp45"></el-option>
            <el-option label="sp50" value="sp50"></el-option>
            <el-option label="sp53" value="sp53"></el-option>
            <el-option label="sp55" value="sp55"></el-option>
            <el-option label="sp58" value="sp58"></el-option>
            <el-option label="sp58+" value="sp58+"></el-option>
            <el-option label="sp60" value="sp60"></el-option>
            <el-option label="sp60+" value="sp60+"></el-option>
            <el-option label="sp61" value="sp61"></el-option>
            <el-option label="sp61+" value="sp61+"></el-option>
            <el-option label="sp62" value="sp62"></el-option>
            <el-option label="sp62+" value="sp62+"></el-option>
            <el-option label="sp63" value="sp63"></el-option>
            <el-option label="sp63+" value="sp63+"></el-option>
            <el-option label="sp64" value="sp64"></el-option>
            <el-option label="sp64+" value="sp64+"></el-option>
            <el-option label="sp65" value="sp65"></el-option>
            <el-option label="sp65+" value="sp65+"></el-option>
            <el-option label="sp66" value="sp66"></el-option>
            <el-option label="sp66+" value="sp66+"></el-option>
            <el-option label="sp67" value="sp67"></el-option>
            <el-option label="sp67+" value="sp67+"></el-option>
            <el-option label="sp68" value="sp68"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="精品">
            <el-switch v-model="good"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getTableData">查询</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="系列" prop="cate2.cate1.name"></el-table-column>
        <el-table-column label="品名" prop="cate2.name"></el-table-column>
        <el-table-column label="分值" prop="score"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="编号" prop="cert"></el-table-column>
        <el-table-column label="来源" prop="host.name"></el-table-column>
        <el-table-column label="交易日期" prop="transdate"></el-table-column>
        <el-table-column label="输入时间" prop="entrytime"></el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserSearch",
  data() {
    return {
      record: {},
      price: {},
      good: false,
      cate1List: [],
      cate2List: [],
      hostList: [],
      tableList: [],
      updateVisable: false,
      currentPage: 1,
      total: 1,
      rules: {
        name: [
          { required: true, message: "请输入系列", trigger: "blur" },
          { min: 1, max: 20, message: "长度在2到20之间", trigger: "blur" },
        ],
        cate1: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        cate2: [
          { required: true, message: "请选择正确的品名", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请选择正确的分值", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择正确的状态", trigger: "blur" },
        ],
        host: [{ required: true, message: "请选择数据来源", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getTableData();
    this.getCate1List();
    this.getHostList();
  },
  methods: {
    handleCurrentChange(val) {
      this.getTableData();
    },
    showDialog(row) {
      console.log(row);
      this.getCate2List(row.cate2.cate1.id);
      this.record = JSON.parse(JSON.stringify(row));
      this.record.cate1_id = row.cate2.cate1.id;
      this.updateVisable = true;
    },
    getTableData() {
      request
        .get("/admin/record/search", {
          params: {
            cate2_id: this.price.cate2_id,
            score: this.price.score,
            good: this.good,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.tableList = res.data.list;
          this.total = res.data.total;
        });
    },
    getCate1List() {
      //发起Cate1请求
      console.log("发起请求");
      request.get("/common/getCate1List", {}).then((res) => {
        this.cate1List = res.data;
      });
    },
    getCate2List(cate1id) {
      request
        .get("/common/getCate2List", {
          params: {
            id: cate1id,
          },
        })
        .then((res) => {
          this.cate2List = res.data;
        });
    },
    getHostList() {
      //发起Cate1请求
      request.get("/common/getHostList", {}).then((res) => {
        this.hostList = res.data;
      });
    }
  },
};
</script>

<style scoped>
</style>
