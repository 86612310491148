<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="20">
        
        <el-col :span="4">
            <el-card shadow="hover" class="card-red">
            <span>今日登录用户：{{ today.cnt }} 人</span>
             </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="hover" class="card-green">
            <span>真实交费用户：{{ real.cnt }} 人</span>
             </el-card>
          </el-col>
          
       
        <el-col :span="4" v-for="item in vip">
          <el-card shadow="hover">
            <span v-if="item.vip == 0">普通用户</span
            ><span v-else-if="item.vip == 1">会 员</span
            ><span v-else>超级会员</span>数量：{{ item.cnt }}
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card" :gutter="20">
      <el-row :gutter="20">
        <el-col :span="4" v-for="item in order">
          <el-card shadow="hover">
            {{ item.month }}个月 订单数：{{ item.cnt }} 个</el-card
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card" style="display: inline-block; width: 47%">
      <div id="vipChart" style="width: 600px; height: 400px"></div>
    </el-card>
    <el-card class="box-card" style="display: inline-block; width: 47%">
      <div id="orderChart" style="width: 600px; height: 400px"></div>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AdminVip",
  data() {
    return {
      vip: [],
      order: [],
      real: {},
      today: {},
      x1: [],
      y1: [],
      x2: [],
      y2: [],
      vipoption: {
        series: [
          {
            name: "访问来源",
            type: "pie", // 设置图表类型为饼图
            radius: "55%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
            data: [
              // 数据数组，name 为数据项名称，value 为数据项值
              { value: 235, name: "视频广告" },
              { value: 274, name: "联盟广告" },
              { value: 310, name: "邮件营销" },
              { value: 335, name: "直接访问" },
              { value: 400, name: "搜索引擎" },
            ],
          },
        ],
      },
      orderoption: {
        series: [
          {
            name: "访问来源",
            type: "pie", // 设置图表类型为饼图
            radius: "55%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
            data: [
              // 数据数组，name 为数据项名称，value 为数据项值
              { value: 235, name: "视频广告" },
              { value: 274, name: "联盟广告" },
              { value: 310, name: "邮件营销" },
              { value: 335, name: "直接访问" },
              { value: 400, name: "搜索引擎" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
    });
  },
  created() {
    this.getRealWxUser();
    this.getToday();
    this.getVip();
    this.getOrder();
  },
  methods: {
    //查询方法
    getVip(rw) {
      request
        .get("/admin/vip/vipTongji", {
          params: {},
        })
        .then((res) => {
          if (res.code == "0") {
            this.vip = res.data;
            this.initchart1();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    //查询实际的交费用户
    getRealWxUser() {
      request
        .get("/admin/stat/statRealPayWxUser", {
          params: {},
        })
        .then((res) => {
          if (res.code == "0") {
            this.real = res.data;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    //查询今日用户
    getToday() {
      request
        .get("/admin/stat/statTodayUseNumber", {
          params: {},
        })
        .then((res) => {
          if (res.code == "0") {
            this.today = res.data;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    getOrder() {
      request
        .get("/admin/vip/orderTongji", {
          params: {},
        })
        .then((res) => {
          if (res.code == "0") {
            this.order = res.data;
            this.initchart2();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    initchart1() {
      let vips = this.vip;
      let x = [];
      for (let i = 0; i < vips.length; i++) {
        let obj = vips[i];
        let data_obj = {};
        data_obj.value = obj.cnt;
        if (obj.vip == 0) data_obj.name = "普通用户";
        if (obj.vip == 1) data_obj.name = "会员";
        if (obj.vip == 2) data_obj.name = "超级会员";
        x.push(data_obj);
      }
      var option1 = {
        title: {
          text: "VIP用户数量",
          subtext: "占比",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "用户占比",
            type: "pie", // 设置图表类型为饼图
            radius: "55%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
            data: x,
          },
        ],
      };
      var chart1 = this.$echarts.init(document.getElementById("vipChart"));
      chart1.setOption(option1);
    },
    initchart2() {
      let orders = this.order;
      let y = [];
      for (let i = 0; i < orders.length; i++) {
        let obj = orders[i];
        let data_obj = {};
        data_obj.value = obj.cnt;
        data_obj.name = obj.month + "个月";
        y.push(data_obj);
      }
      var option2 = {
        title: {
          text: "订单数量",
          subtext: "占比",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "用户占比",
            type: "pie", // 设置图表类型为饼图
            radius: "55%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
            data: y,
          },
        ],
      };
      var chart1 = this.$echarts.init(document.getElementById("orderChart"));
      chart1.setOption(option2);
    },
  },
};
</script>

<style scoped>
.box-card {
  margin: 10px 20px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.card-red{
  background-color: indianred;
  color: #FFFFFF;
}
.card-green{
  background-color: rgb(62, 207, 98);
  color: #FFFFFF;
}
</style>
