<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0">
        <el-form :inline="true">
          <el-form-item>
            <el-button type="success" @click="addRulesVisable = true"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <el-table :data="dataList" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="分数" prop="score"></el-table-column>
        <el-table-column label="描述" prop="description"></el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="缩略图">
          <template slot-scope="scope">
            <el-image :fit="fit" :src="scope.row.img"></el-image
          ></template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="warning" @click="showDialog(scope.row)">修改</el-button>
            <el-button type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加品名的弹出框 -->
      <el-dialog title="提示" :visible.sync="addRulesVisable" width="50%">
        <el-form
          :model="rules"
          :rules="input_rules"
          ref="addRef"
        >
          <el-form-item label="评级分值" prop="name">
            <el-input v-model="rules.score"></el-input>
          </el-form-item>
          <el-form-item label="评级详细规则" prop="name">
            <el-input type="textarea" v-model="rules.description"></el-input>
          </el-form-item>
          <el-form-item label="图片上传">
            <el-upload
              ref="upload1"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess1"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="排序" prop="name">
            <el-input v-model="rules.sort"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addRulesVisable = false">取 消</el-button>
          <el-button type="primary" @click="addRules">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 修改品名的弹出框 -->
      <el-dialog title="提示" :visible.sync="updateVisable" width="50%">
        <el-form
          :model="currentRules"
          :rules="input_rules"
          ref="updateRef"
        >
        <el-form-item label="评级分值" prop="name">
            <el-input v-model="currentRules.score"></el-input>
          </el-form-item>
          <el-form-item label="评级详细规则" prop="name">
            <el-input type="textarea" v-model="currentRules.description"></el-input>
          </el-form-item>
          <el-form-item label="图片上传">
            <el-upload
              ref="upload2"
              action="https://www.yxyqb.xyz/api/files/upload"
              :on-success="imgSuccess2"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="排序" prop="name">
            <el-input v-model="currentRules.sort"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">          
          <el-button @click="updateVisable = false">取 消</el-button>
          <el-button type="primary" @click="update">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: "AdminRules",
  data() {
    return {
      dataList: [],
      addRulesVisable: false,
      updateVisable: false,
      rules: {},
      currentRules: {},
      input_rules: {
        score: [
          { required: true, message: "请输入合理的名称", trigger: "blur" },
        ],
        des: [
          { required: true, message: "请输入合理的名称", trigger: "blur" },
        ]
      },
      
    };
  },
  created() {
    this.getRulesList();
  },
  methods: {
    imgSuccess1(res) {
      this.rules.img = res.msg;
    },
    imgSuccess2(res) {
      this.currentRules.img = res.msg;
    },
    getRulesList() {
      //发起请求
      request.get("/admin/ratingrules", {}).then((res) => {
        this.dataList = res.data;
      });
    },
    showDialog(row) {
      this.currentRules = JSON.parse(JSON.stringify(row)); //深拷贝
      this.updateVisable = true;
    },


    addRules() {
      this.$refs.addRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/ratingrules", this.rules).then((res) => {
          if (res.code == "0") {
            this.addRulesVisable = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs.upload1.clearFiles();
            this.getRulesList()
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    update() {
      this.$refs.updateRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/ratingrules/update", this.currentRules).then((res) => {
          if (res.code == "0") {
            this.updateVisable = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs.upload2.clearFiles();
            this.getRulesList()
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    


    del(id) {
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/ratingrules/del/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getRulesList()
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style scoped>
</style>
