<template>
  <div class="login-container">
    <div class="footer">
      <el-divider></el-divider>
      <div class="copyright">Copyright ©2022 yxyqb.xyz 迅捷咨询 版权所有</div>
      <a href="https://beian.miit.gov.cn">晋ICP备2021015751号-1</a> &nbsp;&nbsp;&nbsp;
      <a href="https://beian.miit.gov.cn"> 信息产业部备案管理系统网址</a>
    </div>
    <div class="login-box">
      <div class="avatar-box">
        <img src="../assets/logo.png" alt="" />
      </div>
      <el-form
        :model="loginForm"
        ref="loginFormRef"
        :rules="loginRules"
        label-width="0px"
        class="login-form"
      >
        <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user"
            placeholder="用户名"
            v-model="loginForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            placeholder="密码"
            v-model="loginForm.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="sub">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      loginForm: {},
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, max: 18, message: "长度在3到18个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 3, max: 18, message: "长度在3到18个字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    sub() {
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) return;
        //否则发起请求登录
        request.post("/login", this.loginForm).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            //保存登录信息
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("username", res.data.username);
            sessionStorage.setItem("nickname", res.data.nickname);
            sessionStorage.setItem("auth", res.data.auth);
            let auth = res.data.auth;
            if (auth >= 5) this.$router.push("admin");
            else if (auth == 1 || auth == 3) this.$router.push("user");
            else this.$router.push("user/updateImg");
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.login-container {
  background-color: rgb(237, 243, 248);
  height: 100%;
}
.login-box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login-box .avatar-box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eee;
}
.login-box .avatar-box img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #eee;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.login-form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 25px;
  box-sizing: border-box;
}
.footer {
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: rgb(237, 243, 248);
  transform: translate(-50%, -50%);
}
a {
  text-decoration: none;
}
a:link {
  font-size: 16px;
  color: 	#696969;
}
a:visited {
  font-size: 16px;
  color: 	#696969;
}
a:hover {
  color: black;
}
.copyright {
  font-size: 15px;
  color: 	#7a7979;
  margin-bottom: 2px;
}
</style>
