import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import AdminUsers from '../views/admin/AdminUsers.vue'
import AdminCate1 from '../views/admin/AdminCate1.vue'
import AdminCate2 from '../views/admin/AdminCate2.vue'
import AdminPrice from '../views/admin/AdminPrice.vue'
import AdminHost from '../views/admin/AdminHost.vue'
import AdminRecord from '../views/admin/AdminRecord.vue'
import AdminVerify from '../views/admin/AdminVerify.vue'
import AdminExcel from '../views/admin/AdminExcel.vue'
import AdminImg from '../views/admin/AdminImg.vue'
import AdminScore from '../views/admin/AdminScore.vue'
import AdminCode from '../views/admin/AdminCode.vue'
import AdminLog from '../views/admin/AdminLog.vue'
import AdminVip from '../views/admin/AdminVip.vue'
import AdminTongji from '../views/admin/AdminTongji.vue'
import AdminOrder from '../views/admin/AdminOrder.vue'
import AdminRules from '../views/admin/AdminRules.vue'
import AdminTemplate from '../views/admin/AdminTemplate.vue'

import UserInput from '../views/user/UserInput.vue'
import UserSearch from '../views/user/UserSearch.vue'
import UserResrtPassword from '../views/user/UserResetPassword.vue'
import UserStat from '../views/user/UserStat.vue'
import UserUpload from '../views/user/UserUpload.vue'
import UserHost from '../views/user/UserHost.vue'
import UserRecord from '../views/user/UserRecord.vue'
import UserImg from '../views/user/UserImg.vue'
import UserCopper from '../views/user/UserCopper.vue'

import AdminCopperCate1 from '../views/copper/AdminCopperCate1.vue'
import AdminCopperCate2 from '../views/copper/AdminCopperCate2.vue'
import AdminCopperHost from '../views/copper/AdminCopperHost.vue'
import CopperVerify from '../views/copper/CopperVerify.vue'
import CopperRecord from '../views/copper/CopperRecord.vue'
import CopperCategory from '../views/copper/CopperCategory.vue'

import AdminLayout from '../layout/AdminLayout.vue'
import UserLayout from '../layout/UserLayout.vue'
import CopperLayout from '../layout/CopperLayout.vue'

import Test from '../views/test.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    component:Test
  },
  {
    path: '/login',
    component:Login
  },
  {
    path: '/admin',
    component: AdminLayout,
    redirect: '/admin/verify',
    children: [
      {
        path: 'users',
        component: AdminUsers
      },
      {
        path: 'cate1',
        component: AdminCate1
      },
      {
        path: 'cate2',
        component: AdminCate2
      },
      {
        path: 'price',
        component: AdminPrice
      },
      {
        path: 'verify',
        component: AdminVerify
      },
      {
        path: 'record',
        component: AdminRecord
      },
      {
        path: 'host',
        component: AdminHost
      },
      {
        path: 'excel',
        component: AdminExcel
      },
      {
        path: 'img',
        component: AdminImg
      },
      {
        path: 'score',
        component: AdminScore
      },
      {
        path: 'code',
        component: AdminCode
      },
      {
        path: 'vip',
        component: AdminVip
      },
      {
        path: 'tongji',
        component: AdminTongji
      },
      {
        path: 'order',
        component: AdminOrder
      },
      {
        path: 'rules',
        component: AdminRules
      },
      {
        path: 'template',
        component: AdminTemplate
      }
    ]
  },
  {
    path: '/copper',
    component: CopperLayout,
    redirect: '/copper/verify',
    children: [
      {
        path: 'cate1',
        component: AdminCopperCate1
      },
      {
        path: 'cate2',
        component: AdminCopperCate2
      },
      {
        path: 'host',
        component: AdminCopperHost
      },
      {
        path: 'verify',
        component: CopperVerify
      },
      {
        path: 'record',
        component: CopperRecord
      },
      {
        path: 'category',
        component: CopperCategory
      }
    ]
  },
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/input',
    children: [
      {
        path: 'input',
        component: UserInput
      },
      {
        path: 'search',
        component: UserSearch
      },
      {
        path: 'info',
        component: UserResrtPassword
      },
      {
        path: 'stat',
        component: UserStat
      },
      {
        path: 'upload',
        component: UserUpload
      },
      {
        path: 'host',
        component: UserHost
      },
      {
        path: 'record',
        component: UserRecord
      },
      {
        path: 'updateImg',
        component: UserImg
      },
      {
        path: 'copper',
        component: UserCopper
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//挂载一个路由导航守卫
router.beforeEach(async (to, from, next) => {
  //to将要访问的路径
  //from 从哪里来
  //next 函数，表示放行或者跳转
  if(to.path == '/login') return next();
  const tokenStr = sessionStorage.getItem('token')
  if(!tokenStr) return next('/login')
  next()
})
export default router
