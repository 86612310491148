<template>
  <el-container class="home-container">
    <el-header>
      <div>
        <span>银元录入系统</span>
      </div>
      <div>
        <span class="nick">当前用户：{{ nickname }}</span>
        <el-button @click="logout" size="medium" round>退出</el-button>
      </div>
    </el-header>
    <el-main>
      <el-menu
        class="home-menu"
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        router
      >
        <el-menu-item index="/user/input" v-if="auth == 1 || auth == 3 || auth >= 5">输入</el-menu-item>
        <el-menu-item index="/user/copper" v-if="auth == 1 || auth == 3 || auth >= 5">铜钱</el-menu-item>
        <el-menu-item index="/user/host" v-if="auth == 1 || auth == 3">查询记录</el-menu-item>
        <el-menu-item index="/user/record" v-if="auth == 2">按类查找</el-menu-item>
        <el-menu-item index="/user/upload" v-if="auth == 1 || auth == 3 || auth >= 5" >上传PCGS图片</el-menu-item>
        <el-menu-item index="/user/updateImg" v-if="auth == 2">修改图片</el-menu-item>
        <el-submenu index="2">
          <template slot="title">用户选项</template>
          <el-menu-item index="/user/search">修改</el-menu-item>
          <el-menu-item index="/user/stat">统计</el-menu-item>
          <el-menu-item index="/user/info">修改密码</el-menu-item>
        </el-submenu>
      </el-menu>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      nickname: "",
      auth: 0,
    };
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    getInit() {
      this.nickname = sessionStorage.getItem("nickname");
      this.auth = sessionStorage.getItem("auth");
    },
  },
  created() {
    this.getInit()
  },
};
</script>

<style scoped>
.home-container {
  height: 100%;
}
.home-menu {
  width: 100%;
}
.el-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
}
.el-aside {
  background-color: white;
}
.el-main {
  background-color: #eeeeee;
}
.nick {
  font-size: 18px;
  margin: 3px 12px;
}
</style>
