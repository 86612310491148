<template>
  <div>
    <el-card class="box-card">
      <el-table :data="tableData" border stripe>
        <el-table-column
          label="标题"
          prop="title"
          width="100px"
        ></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
        <el-table-column label="购买时长">
          <template slot-scope="scope">{{ scope.row.month }} 个月</template>
        </el-table-column>
        <el-table-column label="订单金额">
          <template slot-scope="scope">{{ scope.row.totalFee }} 元</template>
        </el-table-column>
        <el-table-column label="时间" prop="entrytime"></el-table-column>
        <el-table-column label="vip类型">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.status == false">未支付</el-tag>
            <el-tag type="success" v-else>已支付</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "AdminVip",
  data() {
    return {
      searchForm: {
        value: "",
      },
      tableData: [],
      currentPage: 1,
      total: 1,
    };
  },
  created() {
    console.log('dawdwad')
    this.search();
  },
  methods: {
    //查询方法
    search() {
      request.get("/admin/vip/getOrderAll", {
            params: {
            currentPage: this.currentPage,
          }
        }).then((res) => {
          if (res.code == "0") {
            this.tableData = res.data.list;
          this.total = res.data.total;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.search();
    },
  },
};
</script>

<style scoped>
</style>
