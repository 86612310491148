<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0" style="margin-bottom: 10px">
        <el-col :span="24">
          <el-button type="primary" @click="verify" v-if="verifyFlag" style="float:right">一键审核</el-button>
          <el-button type="success" @click="verified" v-if="verifyFlag">查看今日已审核</el-button>
          <el-button type="info" @click="getverify" v-else>查看今日未审核</el-button>
        </el-col>
      </el-row>
      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="系列" prop="category.name"></el-table-column>
        <el-table-column label="分值" prop="score"></el-table-column>
        <el-table-column label="规格" prop="shape"></el-table-column>
        <el-table-column label="评级公司" prop="corporation"></el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="编号" prop="cert"></el-table-column>
        <el-table-column label="来源" prop="host.name"></el-table-column>
        <el-table-column label="交易日期" prop="transdate"></el-table-column>
        <el-table-column label="输入时间" prop="entrytime"></el-table-column>
        <el-table-column label="操作员" prop="user.nickname"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="!scope.row.verify">未审核</el-tag>
            <el-tag type="success" v-else>已审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="查看图片">
          <template slot-scope="scope">
            <el-button
              size="mini"
              round
              type="info"
              @click="showImg(scope.row.cert)"
              >查看图片</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.user_id == 10"
              @click="showProcess(scope.row)"
              size="mini"
              round
              type="primary"
              >审核</el-button
            >
            <el-button
              v-else
              size="mini"
              round
              type="primary"
              @click="verifyOne(scope.row.id)"
              >审核</el-button
            >
            <el-button
              size="mini"
              round
              type="danger"
              @click="del(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <el-dialog title="图片查看" :visible.sync="showImgVisable" width="40%">
        <el-image fit="fit" :src="imgurl"></el-image>
        <el-upload
          ref="uploader"
          class="upload-demo"
          drag
          action="/api/files/uploadPcgsImg"
          :on-success="imgSuccess"
          :before-upload="beforeuploadImg"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过3M
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateImg" type="primary">提交</el-button>
          <el-button @click="showImgVisable = false">关闭</el-button>
        </span>
      </el-dialog>

      <el-dialog title="处理" :visible.sync="showProcessVisable" width="1600px">
        <div class="main1">
          <div class="left">
            <div class="intep">裁剪图片*******************************</div>
            <el-button-group style="margin: 0px 0px 10px 0px">
              <el-button
                type="primary"
                icon="el-icon-arrow-left"
                @click="img1SubHandle"
                >上一张</el-button
              >
              <el-button type="primary" @click="img1AddHandle"
                >下一张<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
            <el-button-group style="margin: 0px 0px 10px 10px">
              <el-button
                type="primary"
                icon="el-icon-arrow-left"
                @click="img2SubHandle"
                >上一张</el-button
              >
              <el-button type="primary" @click="img2AddHandle"
                >下一张<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
            <div>
              <div class="image-copper">
                <VueCropper
                  ref="cropper1"
                  :img="option.img1"
                  :info="true"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :fixedBox="option.fixedBox"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :centerBox="option.centerBox"
                  :infoTrue="option.infoTrue"
                  @realTime="realTime1"
                ></VueCropper>
              </div>
              <div class="image-copper">
                <VueCropper
                  ref="cropper2"
                  :img="option.img2"
                  :info="true"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :fixedBox="option.fixedBox"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :centerBox="option.centerBox"
                  :infoTrue="option.infoTrue"
                  @realTime="realTime2"
                ></VueCropper>
              </div>
            </div>
            <div class="intep">预览结果*********************</div>
            <div>
              <div
                class="previews"
                :style="{
                  'border-radius': '50%',
                  width: previews1.w + 'px',
                  height: previews1.h + 'px',
                  overflow: 'hidden',
                  margin: '5px',
                }"
              >
                <div :style="previews1.div">
                  <img :src="previews1.url" :style="previews1.img" />
                </div>
              </div>
              <div
                class="previews"
                :style="{
                  'border-radius': '50%',
                  width: previews2.w + 'px',
                  height: previews2.h + 'px',
                  overflow: 'hidden',
                  margin: '5px',
                }"
              >
                <div :style="previews2.div">
                  <img :src="previews2.url" :style="previews2.img" />
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="right-top">
              <div class="right-cankao" v-if="process.score != '裸币'">
                <el-button-group
                  style="display: block; margin: 0px 0px 10px 10px"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-arrow-left"
                    @click="img3SubHandle"
                    >上一张</el-button
                  >
                  <el-button type="primary" @click="img3AddHandle"
                    >下一张<i class="el-icon-arrow-right el-icon--right"></i
                  ></el-button>
                </el-button-group>
                <img :src="option.img3" width="300px" />
              </div>
              <div class="right-form">
                <div class="right-form-item">
                  <span class="labb">原始地址：</span>
                  <a :href="process.url" target="_blank"
                      ><button>点我跳转</button></a
                    ></div>
                <el-form
                  :model="process"
                  :rules="rules"
                  ref="recordRef"
                  label-width="100px"
                >
                  <el-form-item label="名称">
                    {{ process.name }}
                  </el-form-item>
                  <el-form-item label="分类" prop="cate2_id">
                    <el-cascader
                      v-model="parent_list"
                      :options="options"
                      @change="handleChange"
                      :props="{ expandTrigger: 'hover' }"
                      :show-all-levels="false"
                    ></el-cascader>
                  </el-form-item>

                  <el-form-item label="评级公司" prop="price">
                    <el-select
                      v-model="process.corporation"
                      placeholder="请选择评级公司"
                      :disabled="process.score == '裸币'"
                    >
                      <el-option label="公博" value="公博"></el-option>
                      <el-option label="华夏" value="华夏"></el-option>
                      <el-option label="保粹" value="保粹"></el-option>
                      <el-option label="闻德" value="闻德"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="分数" prop="cert">
                    <el-input
                      v-model="process.score"
                      style="width: 223px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="编号" prop="cert">
                    <el-input
                      v-model="process.cert"
                      style="width: 223px"
                      :disabled="process.score == '裸币'"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="成交价" prop="price">
                    <el-input
                      v-model="process.price"
                      style="width: 223px"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="规格" prop="price">
                    <el-input
                      v-model="process.shape"
                      style="width: 223px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="数据来源" prop="host_id">
                    <el-select
                      v-model="process.host_id"
                      placeholder="请选择数据来源"
                      disabled
                    >
                      <el-option
                        v-for="item in hostList"
                        :label="item.name"
                        :value="item.id"
                        :key="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="imgStart()" type="primary" :loading="waitFlag"
            >提交</el-button
          >
          <el-button @click="showProcessVisable = false">关闭</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserSearch",
  data() {
    return {
      record: {},
      cate1List: [],
      cate2List: [],
      hostList: [],
      tableList: [],
      parent_list: [],
      options: [],
      updateVisable: false,
      showImgVisable: false,
      showProcessVisable: false,
      process: {},
      currentPage: 1,
      total: 1,
      imgurl: "",
      cert: "",
      img: "",
      waitFlag: false,
      imgList1: [],
      imgList2: [],
      imgIndex1: 0,
      imgIndex2: 1,
      imgIndex3: 0,
      verifyFlag: true,
      dataUrl: '/admin/copper/record',
      basePci: "https://imgali.huaxiaguquan.com/pic/",
      rules: {
        name: [
          { required: true, message: "请输入系列", trigger: "blur" },
          { min: 1, max: 20, message: "长度在2到20之间", trigger: "blur" },
        ],
        cate1: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        cate2: [
          { required: true, message: "请选择正确的品名", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请选择正确的分值", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择正确的状态", trigger: "blur" },
        ],
        host: [{ required: true, message: "请选择数据来源", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
      option: {
        img1: "", // 裁剪图片的地址
        img2: "", // 裁剪图片的地址
        img3: "",
        info: false, // 裁剪框的大小信息(即是否显示裁剪框的宽高信息)
        outputSize: 1, // 裁剪生成图片的质量(0.1~1之间)
        outputType: "jpeg", // 裁剪生成图片的格式(jpg(jpg 需要传入jpeg))
        canScale: false, // 图片是否允许滚轮缩放(这个属性貌似没得用，不管设置true，false都可以滚轮缩放)
        autoCrop: true, // 是否默认生成截图框
        // 注：这里需要注意，如果是裁剪成圆形图片，那么截图框的宽高就是必须设置的，且最好宽高一样
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例(这是比例，按两个值的比值大小进行截图框的宽高的设置，[1,1]和[100,100]是一样的)
        full: true, // 是否输出原图比例的截图
        canMove: false, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      base64Data: {
        dataURL: "", // 用url方式表示的base64图片数据
        type: "image/jpeg", //文件类型
      },
      previews1: {},
      previews2: {},
    };
  },
  created() {
    this.getTableData();
    this.getCateList();
    this.getHostList();
  },
  methods: {
    verified() {
      this.verifyFlag = false
      this.currentPage = 1;
      this.dataUrl = '/admin/copper/record/verified'
      this.getTableData()
    },
    getverify() {
      this.verifyFlag = true
      this.currentPage = 1;
      this.dataUrl = '/admin/copper/record'
      this.getTableData()
    },
    find(id) {
      console.log("开始");
      for (let i = 0; i < this.options.length; i++) {
        let cate1 = this.options[i].children;
        for (let j = 0; j < cate1.length; j++) {
          let cate2 = cate1[j].children;
          for (let k = 0; k < cate2.length; k++) {
            if (id == cate2[k].value) {
              let result = [];
              result.push(this.options[i].value);
              result.push(cate1[j].value);
              result.push(cate2[k].value);
              this.parent_list = result;
              console.log(this.parent_list);
              return;
            }
          }
        }
      }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getTableData();
    },
    handleChange(value) {
      console.log("触发" + value);
      console.log("触发" + this.parent_list);
      this.process.cate2_id = value[value.length - 1];
    },
    showDialog(row) {
      console.log(row);
      this.getCate2List(row.cate2.cate1.id);
      this.record = JSON.parse(JSON.stringify(row));
      this.record.cate1_id = row.cate2.cate1.id;
      this.updateVisable = true;
    },
    img1AddHandle() {
      let n = this.imgList1.length;
      if (this.imgIndex1 + 1 >= n) {
        this.imgIndex1 = 0;
      } else this.imgIndex1 = this.imgIndex1 + 1;
      this.option.img1 = this.basePci + this.imgList1[this.imgIndex1];
      let _this = this;
      this.setAvatarBase64(this.option.img1, (base64) => {
        _this.option.img1 = base64;
      });
    },
    img1SubHandle() {
      let n = this.imgList1.length;
      if (this.imgIndex1 == 0) {
        this.imgIndex1 = n - 1;
      } else this.imgIndex1 = this.imgIndex1 - 1;
      this.option.img1 = this.basePci + this.imgList1[this.imgIndex1];
      let _this = this;
      this.setAvatarBase64(this.option.img1, (base64) => {
        _this.option.img1 = base64;
      });
    },
    img2AddHandle() {
      let n = this.imgList1.length;
      if (this.imgIndex2 + 1 >= n) {
        this.imgIndex2 = 0;
      } else this.imgIndex2 = this.imgIndex2 + 1;
      this.option.img2 = this.basePci + this.imgList1[this.imgIndex2];
      let _this = this;
      this.setAvatarBase64(this.option.img2, (base64) => {
        _this.option.img2 = base64;
      });
    },
    img2SubHandle() {
      let n = this.imgList1.length;
      if (this.imgIndex2 == 0) {
        this.imgIndex2 = n - 1;
      } else this.imgIndex2 = this.imgIndex2 - 1;
      this.option.img2 = this.basePci + this.imgList1[this.imgIndex2];
      let _this = this;
      this.setAvatarBase64(this.option.img2, (base64) => {
        _this.option.img2 = base64;
      });
    },
    img3AddHandle() {
      let n = this.imgList2.length;
      if (this.imgIndex3 + 1 >= n) {
        this.imgIndex3 = 0;
      } else this.imgIndex3 = this.imgIndex3 + 1;
      this.option.img3 = this.basePci + this.imgList2[this.imgIndex3];
    },
    img3SubHandle() {
      let n = this.imgList2.length;
      if (this.imgIndex3 == 0) {
        this.imgIndex3 = n - 1;
      } else this.imgIndex3 = this.imgIndex3 - 1;
      this.option.img3 = this.basePci + this.imgList2[this.imgIndex3];
    },
    //点击处理之后的预处理
    showProcess(processObj) {
      console.log(processObj)
      this.process = processObj;
      this.imgIndex1 = 0;
      this.imgIndex2 = 1;
      this.imgIndex3 = 0;
      let flag = processObj.score == "裸币";
      this.imgList1 = JSON.parse(processObj.img1);
      if (!flag) this.imgList2 = JSON.parse(processObj.img2);
      this.option.img1 = this.basePci + this.imgList1[this.imgIndex1];
      this.option.img2 = this.basePci + this.imgList1[this.imgIndex2];
      if (!flag)
        this.option.img3 = this.basePci + this.imgList2[this.imgIndex3];
      this.editCropper();
      this.find(processObj.cate2_id);
      this.showProcessVisable = true;
    },
    getTableData() {
      console.log("初始化");
      request
        .get(this.dataUrl, {
          params: {
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.tableList = res.data.list;
          this.total = res.data.total;
        });
    },
    getCateList() {
      //发起请求
      request.get("/admin/copper/category/getAllCategory", {}).then((res) => {
        this.options = res.data;
      });
    },
    getHostList() {
      //发起Cate1请求
      request.get("/common/getCopperHostList", {}).then((res) => {
        this.hostList = res.data;
      });
    },
    showImg(cert) {
      this.cert = cert;
      request
        .get("/admin/copper/img", {
          params: {
            cert: cert,
          },
        })
        .then((res) => {
          if (res.code == "0") {
            this.imgurl = "/api/files/getPcgsImg?fileName=" + res.data.img;
            this.showImgVisable = true;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    updateImg() {
      let cert = this.cert;
      request
        .post("/admin/copper/img", {
          cert: cert,
          img: this.img,
        })
        .then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs.uploader.clearFiles();
            this.showImgVisable = false;
            this.getTableData();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    imgSuccess(res) {
      this.img = res.msg;
    },
    beforeuploadImg(file) {
      const isLt3M = file.size / 1024 / 1024 < 5;

      console.log("图片上传：" + isLt3M);
      if (!isLt3M) {
        this.$message({
          message: "图片大小不能超过5M!请重新选择!",
          type: "warning",
        });
        return false; //必须加上return false; 才能阻止
      }
    },
    verify() {
      this.$confirm("是否全部审核通过？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.post("/admin/copper/record/verify").then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: res.msg,
          });

          this.getTableData();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    //删除
    del(id) {
      console.log(id);
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/copper/record/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    good(id) {
      console.log(id);
      this.$confirm("是否设为精品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/copper/record/good/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    verifyOne(id) {
      this.$confirm("是否审核通过？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request
            .get("/admin/copper/record/verifyOne", {
              params: {
                id: id,
              },
            })
            .then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.getTableData();
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    realTime1(data) {
      this.previews1 = data;
      //console.log(data)
    },
    realTime2(data) {
      this.previews2 = data;
      //console.log(data)
    },
    editCropper() {
      let _this = this;
      // 设置头像base64
      // 其中this.avatar为当前头像
      this.setAvatarBase64(this.option.img1, (base64) => {
        _this.option.img1 = base64;
      });
      this.setAvatarBase64(this.option.img2, (base64) => {
        _this.option.img2 = base64;
      });
    },
    // 设置头像base64
    setAvatarBase64(src, callback) {
      let _this = this;
      let image = new Image();
      // 处理缓存
      image.src = src + "?v=" + Math.random();
      // 支持跨域图片
      image.crossOrigin = "*";
      image.onload = function () {
        let base64 = _this.imageBase64(image);
        callback && callback(base64);
      };
    },
    //将图片转换成base64
    imageBase64(image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // // 可选其他值 image/jpeg
      return canvas.toDataURL("image/jpeg");
    },

    imgStart() {
      this.waitFlag = true;
      var data = new FormData();
      this.$refs.cropper1.getCropBlob((blob) => {
        data.append("file1", blob, "1.jpeg");
        this.$refs.cropper2.getCropBlob((blob) => {
          data.append("file2", blob, "2.jpeg");
          request
            .post("/files/uploadCopperImage", data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              this.process.img = response.msg;
              this.process.user = null;
              request
                .post("/admin/copper/record/auto", this.process)
                .then((res) => {
                  if (res.code == "0") {
                    this.$message({
                      type: "success",
                      message: res.msg,
                    });
                    this.getTableData();
                    this.showProcessVisable = false;
                    this.waitFlag = false;
                  } else {
                    this.$message({
                      type: "error",
                      message: res.msg,
                    });
                    this.waitFlag = false;
                  }
                });
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
    },
  },
};
</script>

<style scoped>
.intep {
  font-size: 20px;
  font-weight: 400;
  color: #1f2f3d;
  margin: 5px 0px 5px 3px;
}
.image-copper {
  display: inline-block;
  height: 250px;
  width: 250px;
}
.previews {
  display: inline-block;
}
/deep/ .cropper-view-box {
  border-radius: 50%;
}

.left {
  float: left;
  border: 1px solid rgb(204, 201, 201);
  height: 100%;
  padding: 10px;
}

.right {
  float: left;
  border: 1px solid rgb(204, 201, 201);
  margin-left: 10px;
  height: 100%;
  padding: 10px;
}

.right-cankao {
  display: inline-block;
}
.right-form {
  display: inline-block;
}
.label-right {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.main1 {
  height: 600px;
}
.right-form-item{
  margin: 15px 0px 10px 30px;
}
</style>
