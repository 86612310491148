<template>
  <div>
    <el-card class="box-card">
      <el-form
        :model="record"
        :rules="rules"
        ref="recordRef"
        label-width="100px"
      >
        <el-form-item label="系列" prop="cate1_id">
          <el-select
            v-model="record.cate1_id"
            placeholder="系列"
            @change="getCate2List"
          >
            <el-option
              v-for="item in cate1List"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品名" prop="cate2_id">
          <el-select v-model="record.cate2_id" placeholder="品名">
            <el-option
              v-for="item in cate2List"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="price">
          <el-select v-model="record.score" placeholder="请选择分值">
            <el-option label="45分" value="45"></el-option>
            <el-option label="40分" value="40"></el-option>
            <el-option label="68分" value="68"></el-option>
            <el-option label="67+分" value="67+"></el-option>
            <el-option label="67分" value="67"></el-option>
            <el-option label="66+分" value="66+"></el-option>
            <el-option label="66分" value="66"></el-option>
            <el-option label="65+分" value="65+"></el-option>
            <el-option label="65分" value="65"></el-option>
            <el-option label="64+分" value="64+"></el-option>
            <el-option label="64分" value="64"></el-option>
            <el-option label="63+分" value="63+"></el-option>
            <el-option label="63分" value="63"></el-option>
            <el-option label="62+分" value="62+"></el-option>
            <el-option label="62分" value="62"></el-option>
            <el-option label="61+分" value="61+"></el-option>
            <el-option label="61分" value="61"></el-option>
            <el-option label="60分" value="60"></el-option>
            <el-option label="58+分" value="58+"></el-option>
            <el-option label="58分" value="58"></el-option>
            <el-option label="55分" value="55"></el-option>
            <el-option label="53分" value="53"></el-option>
            <el-option label="50分" value="50"></el-option>
            <el-option label="35分" value="35"></el-option>
            <el-option label="30分" value="30"></el-option>
            <el-option label="91分" value="91"></el-option>
            <el-option label="92分" value="92"></el-option>
            <el-option label="93分" value="93"></el-option>
            <el-option label="94分" value="94"></el-option>
            <el-option label="95分" value="95"></el-option>
            <el-option label="97分" value="97"></el-option>
            <el-option label="98分" value="98"></el-option>
            <el-option label="UNC92分" value="UNC92"></el-option>
            <el-option label="UNC97分" value="UNC97"></el-option>
            <el-option label="UNC98分" value="UNC98"></el-option>
            <el-option label="sp40" value="sp40"></el-option>
            <el-option label="sp45" value="sp45"></el-option>
            <el-option label="sp50" value="sp50"></el-option>
            <el-option label="sp53" value="sp53"></el-option>
            <el-option label="sp55" value="sp55"></el-option>
            <el-option label="sp58" value="sp58"></el-option>
            <el-option label="sp58+" value="sp58+"></el-option>
            <el-option label="sp60" value="sp60"></el-option>
            <el-option label="sp60+" value="sp60+"></el-option>
            <el-option label="sp61" value="sp61"></el-option>
            <el-option label="sp61+" value="sp61+"></el-option>
            <el-option label="sp62" value="sp62"></el-option>
            <el-option label="sp62+" value="sp62+"></el-option>
            <el-option label="sp63" value="sp63"></el-option>
            <el-option label="sp63+" value="sp63+"></el-option>
            <el-option label="sp64" value="sp64"></el-option>
            <el-option label="sp64+" value="sp64+"></el-option>
            <el-option label="sp65" value="sp65"></el-option>
            <el-option label="sp65+" value="sp65+"></el-option>
            <el-option label="sp66" value="sp66"></el-option>
            <el-option label="sp66+" value="sp66+"></el-option>
            <el-option label="sp67" value="sp67"></el-option>
            <el-option label="sp67+" value="sp67+"></el-option>
            <el-option label="sp68" value="sp68"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="type">
          <el-select v-model="record.type" placeholder="请选择状态">
            <el-option label="原味" value="原味"></el-option>
            <el-option label="原光" value="原光"></el-option>
            <el-option label="黄油" value="黄油"></el-option>
            <el-option label="转光" value="转光"></el-option>
            <el-option label="酱彩" value="酱彩"></el-option>
            <el-option label="五彩" value="五彩"></el-option>
            <el-option label="老包浆" value="老包浆"></el-option>
            <el-option label="白币" value="白币"></el-option>
            <el-option label="金彩" value="金彩"></el-option>
            <el-option label="环彩" value="环彩"></el-option>
            <el-option label="粉彩" value="粉彩"></el-option>
            <el-option label="淡彩" value="淡彩"></el-option>
            <el-option label="红酱彩" value="红酱彩"></el-option>
            <el-option label="黑酱彩" value="黑酱彩"></el-option>
            <el-option label="爆光" value="爆光"></el-option>
            <el-option label="车轮光" value="车轮光"></el-option>
            <el-option label="月牙彩" value="月牙彩"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编号" prop="cert">
          <el-input
            v-model="record.cert"
            style="width: 223px"
          ></el-input>
          <el-button type="info" @click="getPcgsImg" plain
                >验证图片</el-button
              >
        </el-form-item>
        <el-form-item label="原图片" prop="cert" v-if="imgShow">
          <el-image :fit="fit" :src="imgurl" style="height: 250px"></el-image>
        </el-form-item>
        <el-form-item label="上传">
          <el-alert title="当前图片未上传！" type="success" show-icon v-if="!imgShow"> </el-alert>
          <el-alert title="该图片已经存在，如需修改可继续上传！" type="warning" show-icon v-else> </el-alert>
          <el-upload
            ref="upload2"
            class="upload-demo"
            drag
            action="/api/files/uploadPcgsImg"
            :on-success="imgSuccess"
            :before-upload="beforeuploadImg"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
              只能上传jpg/png文件，且不超过5M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="成交价" prop="price">
          <el-input v-model="record.price" style="width: 223px"></el-input>
        </el-form-item>
        <el-form-item label="买家">
          <el-input v-model="record.buyer" style="width: 223px"></el-input>
        </el-form-item>
        <el-form-item label="数据来源" prop="host_id">
          <el-select v-model="record.host_id" placeholder="请选择数据来源">
            <el-option
              v-for="item in hostList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="record.transdate"
            value-format="yyyy-MM-dd"
            style="width: 223px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add()">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserInput",
  data() {
    return {
      record: {},
      cate1List: [],
      cate2List: [],
      hostList: [],
      imgurl: "",
      imgShow: false,
      rules: {
        name: [
          { required: true, message: "请输入系列", trigger: "blur" },
          { min: 1, max: 20, message: "长度在2到20之间", trigger: "blur" },
        ],
        cate1: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        cate2: [
          { required: true, message: "请选择正确的品名", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请选择正确的分值", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择正确的状态", trigger: "blur" },
        ],
        host: [{ required: true, message: "请选择数据来源", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCate1List();
    this.getHostList();
  },
  methods: {
    getCate1List() {
      //发起Cate1请求
      console.log("发起请求");
      request.get("/common/getCate1List", {}).then((res) => {
        this.cate1List = res.data;
      });
    },
    getCate2List() {
      request
        .get("/common/getCate2List", {
          params: {
            id: this.record.cate1_id,
          },
        })
        .then((res) => {
          this.cate2List = res.data;
        });
    },
    getHostList() {
      //发起Cate1请求
      request.get("/common/getHostList", {}).then((res) => {
        this.hostList = res.data;
      });
    },
    resetForm() {
      //重置
      console.log("开始重置");
      console.log("开始重置");
      this.$refs.upload2.clearFiles();
      this.record.cate1_id = "";
      this.record.cate2_id = "";
      this.record.cert = "";
      this.record.price = "";
      this.record.buyer = "";
      this.record.img = "";
      this.imgShow = false
    },
    add() {
      this.$refs.recordRef.validate((valid) => {
        console.log(valid);
        if (!valid) return;
        //发起网络请求
        request.post("/user/input", this.record).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.resetForm();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    imgSuccess(res) {
      this.record.img = res.msg;
    },
    beforeuploadImg(file) {
      const isLt3M = file.size / 1024 / 1024 < 5;

      console.log("图片上传：" + isLt3M);
      if (!isLt3M) {
        this.$message({
          message: "图片大小不能超过5M!请重新选择!",
          type: "warning",
        });
        return false; //必须加上return false; 才能阻止
      }
    },
    getPcgsImg() {
      let tempcert = this.record.cert;
      request
        .get("/admin/img", {
          params: {
            cert: tempcert,
          },
        })
        .then((res) => {
          if (res.code == "0") {
            //图片存在
            this.imgurl = '/api/files/getPcgsImg?fileName=' + res.data.img
            this.imgShow = true;

          } else {
            //图片不存在
            this.imgShow = false;
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
