<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="0">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="系列">
            <el-select
              v-model="price.cate1_id"
              placeholder="系列"
              @change="getCate2List"
            >
              <el-option
                v-for="item in cate1List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品名">
            <el-select v-model="price.cate2_id" placeholder="品名">
              <el-option
                v-for="item in cate2List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分值">
            <el-select v-model="price.score" placeholder="请选择分值">
              <el-option label="45分" value="45"></el-option>
              <el-option label="40分" value="40"></el-option>
              <el-option label="68分" value="68"></el-option>
              <el-option label="67+分" value="67+"></el-option>
              <el-option label="67分" value="67"></el-option>
              <el-option label="66+分" value="66+"></el-option>
              <el-option label="66分" value="66"></el-option>
              <el-option label="65+分" value="65+"></el-option>
              <el-option label="65分" value="65"></el-option>
              <el-option label="64+分" value="64+"></el-option>
              <el-option label="64分" value="64"></el-option>
              <el-option label="63+分" value="63+"></el-option>
              <el-option label="63分" value="63"></el-option>
              <el-option label="62+分" value="62+"></el-option>
              <el-option label="62分" value="62"></el-option>
              <el-option label="61+分" value="61+"></el-option>
              <el-option label="61分" value="61"></el-option>
              <el-option label="60分" value="60"></el-option>
              <el-option label="58+分" value="58+"></el-option>
              <el-option label="58分" value="58"></el-option>
              <el-option label="55分" value="55"></el-option>
              <el-option label="53分" value="53"></el-option>
              <el-option label="50分" value="50"></el-option>
              <el-option label="35分" value="35"></el-option>
              <el-option label="30分" value="30"></el-option>
              <el-option label="91分" value="91"></el-option>
              <el-option label="92分" value="92"></el-option>
              <el-option label="93分" value="93"></el-option>
              <el-option label="94分" value="94"></el-option>
              <el-option label="95分" value="95"></el-option>
              <el-option label="97分" value="97"></el-option>
              <el-option label="98分" value="98"></el-option>
              <el-option label="UNC92分" value="UNC92"></el-option>
              <el-option label="UNC97分" value="UNC97"></el-option>
              <el-option label="UNC98分" value="UNC98"></el-option>
              <el-option label="sp40" value="sp40"></el-option>
              <el-option label="sp45" value="sp45"></el-option>
              <el-option label="sp50" value="sp50"></el-option>
              <el-option label="sp53" value="sp53"></el-option>
              <el-option label="sp55" value="sp55"></el-option>
              <el-option label="sp58" value="sp58"></el-option>
              <el-option label="sp58+" value="sp58+"></el-option>
              <el-option label="sp60" value="sp60"></el-option>
              <el-option label="sp60+" value="sp60+"></el-option>
              <el-option label="sp61" value="sp61"></el-option>
              <el-option label="sp61+" value="sp61+"></el-option>
              <el-option label="sp62" value="sp62"></el-option>
              <el-option label="sp62+" value="sp62+"></el-option>
              <el-option label="sp63" value="sp63"></el-option>
              <el-option label="sp63+" value="sp63+"></el-option>
              <el-option label="sp64" value="sp64"></el-option>
              <el-option label="sp64+" value="sp64+"></el-option>
              <el-option label="sp65" value="sp65"></el-option>
              <el-option label="sp65+" value="sp65+"></el-option>
              <el-option label="sp66" value="sp66"></el-option>
              <el-option label="sp66+" value="sp66+"></el-option>
              <el-option label="sp67" value="sp67"></el-option>
              <el-option label="sp67+" value="sp67+"></el-option>
              <el-option label="sp68" value="sp68"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="精品">
            <el-switch v-model="good"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getTableData">查询</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="编号">
          <el-input v-model="cert" placeholder="请输入编号"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="info" @click="searchCert">搜编号</el-button>
          </el-form-item>
      </el-form>
      <el-table :data="tableList" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="系列" prop="cate2.cate1.name"></el-table-column>
        <el-table-column label="品名" prop="cate2.name"></el-table-column>
        <el-table-column label="分值" prop="score"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="编号" prop="cert"></el-table-column>
        <el-table-column label="来源" prop="host.name"></el-table-column>
        <el-table-column label="交易日期" prop="transdate"></el-table-column>
        <el-table-column label="输入时间" prop="entrytime"></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="info"
              @click="showImg(scope.row.cert)"
              >图片</el-button
            >
            <el-button size="mini" type="success" @click="god(scope.row.id)"
              >精品</el-button
            >
            <el-button size="mini" type="warning" @click="showDialog(scope.row)"
              >修改</el-button
            >
            <el-button size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog title="图片查看" :visible.sync="showImgVisable" >
        <el-image :fit="fit" :src="imgurl"></el-image>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showImgVisable = false">关闭</el-button>
        </span>
      </el-dialog>

      <el-dialog title="提示" :visible.sync="updateVisable" width="40%">
        <el-form
          :model="record"
          :rules="rules"
          ref="recordRef"
          label-width="100px"
        >
          <el-form-item label="系列" prop="cate1_id">
            <el-select
              v-model="record.cate1_id"
              placeholder="系列"
              @change="getCate2List"
            >
              <el-option
                v-for="item in cate1List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品名" prop="cate2_id">
            <el-select v-model="record.cate2_id" placeholder="品名">
              <el-option
                v-for="item in cate2List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分值" prop="price">
            <el-select v-model="record.score" placeholder="请选择分值">
              <el-option label="45分" value="45"></el-option>
              <el-option label="40分" value="40"></el-option>
              <el-option label="68分" value="68"></el-option>
              <el-option label="67+分" value="67+"></el-option>
              <el-option label="67分" value="67"></el-option>
              <el-option label="66+分" value="66+"></el-option>
              <el-option label="66分" value="66"></el-option>
              <el-option label="65+分" value="65+"></el-option>
              <el-option label="65分" value="65"></el-option>
              <el-option label="64+分" value="64+"></el-option>
              <el-option label="64分" value="64"></el-option>
              <el-option label="63+分" value="63+"></el-option>
              <el-option label="63分" value="63"></el-option>
              <el-option label="62+分" value="62+"></el-option>
              <el-option label="62分" value="62"></el-option>
              <el-option label="61+分" value="61+"></el-option>
              <el-option label="61分" value="61"></el-option>
              <el-option label="60+分" value="60+"></el-option>
              <el-option label="60分" value="60"></el-option>
              <el-option label="58+分" value="58+"></el-option>
              <el-option label="58分" value="58"></el-option>
              <el-option label="55分" value="55"></el-option>
              <el-option label="53分" value="53"></el-option>
              <el-option label="50分" value="50"></el-option>
              <el-option label="35分" value="35"></el-option>
              <el-option label="30分" value="30"></el-option>
              <el-option label="91分" value="91"></el-option>
              <el-option label="92分" value="92"></el-option>
              <el-option label="93分" value="93"></el-option>
              <el-option label="94分" value="94"></el-option>
              <el-option label="95分" value="95"></el-option>
              <el-option label="96分" value="96"></el-option>
              <el-option label="97分" value="97"></el-option>
              <el-option label="98分" value="98"></el-option>
              <el-option label="UNC92分" value="UNC92"></el-option>
              <el-option label="UNC97分" value="UNC97"></el-option>
              <el-option label="UNC98分" value="UNC98"></el-option>
              <el-option label="sp40" value="sp40"></el-option>
              <el-option label="sp45" value="sp45"></el-option>
              <el-option label="sp50" value="sp50"></el-option>
              <el-option label="sp53" value="sp53"></el-option>
              <el-option label="sp55" value="sp55"></el-option>
              <el-option label="sp58" value="sp58"></el-option>
              <el-option label="sp58+" value="sp58+"></el-option>
              <el-option label="sp60" value="sp60"></el-option>
              <el-option label="sp60+" value="sp60+"></el-option>
              <el-option label="sp61" value="sp61"></el-option>
              <el-option label="sp61+" value="sp61+"></el-option>
              <el-option label="sp62" value="sp62"></el-option>
              <el-option label="sp62+" value="sp62+"></el-option>
              <el-option label="sp63" value="sp63"></el-option>
              <el-option label="sp63+" value="sp63+"></el-option>
              <el-option label="sp64" value="sp64"></el-option>
              <el-option label="sp64+" value="sp64+"></el-option>
              <el-option label="sp65" value="sp65"></el-option>
              <el-option label="sp65+" value="sp65+"></el-option>
              <el-option label="sp66" value="sp66"></el-option>
              <el-option label="sp66+" value="sp66+"></el-option>
              <el-option label="sp67" value="sp67"></el-option>
              <el-option label="sp67+" value="sp67+"></el-option>
              <el-option label="sp68" value="sp68"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="type">
            <el-select v-model="record.type" placeholder="请选择状态">
              <el-option label="原味" value="原味"></el-option>
              <el-option label="原光" value="原光"></el-option>
              <el-option label="黄油" value="黄油"></el-option>
              <el-option label="转光" value="转光"></el-option>
              <el-option label="酱彩" value="酱彩"></el-option>
              <el-option label="五彩" value="五彩"></el-option>
              <el-option label="老包浆" value="老包浆"></el-option>
              <el-option label="白币" value="白币"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="编号" prop="cert">
            <el-input v-model="record.cert" style="width: 223px"></el-input>
          </el-form-item>
          <el-form-item label="成交价" prop="price">
            <el-input v-model="record.price" style="width: 223px"></el-input>
          </el-form-item>
          <el-form-item label="买家">
            <el-input v-model="record.buyer" style="width: 223px"></el-input>
          </el-form-item>
          <el-form-item label="数据来源" prop="host_id">
            <el-select v-model="record.host_id" placeholder="请选择数据来源">
              <el-option
                v-for="item in hostList"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="record.transdate"
              value-format="yyyy-MM-dd"
              style="width: 223px"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateVisable = false">取 消</el-button>
          <el-button type="primary" @click="updateRecord">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserSearch",
  data() {
    return {
      record: {},
      price: {},
      good: false,
      cate1List: [],
      cate2List: [],
      hostList: [],
      tableList: [],
      updateVisable: false,
      showImgVisable: false,
      currentPage: 1,
      total: 1,
      cert: "",
      rules: {
        name: [
          { required: true, message: "请输入系列", trigger: "blur" },
          { min: 1, max: 20, message: "长度在2到20之间", trigger: "blur" },
        ],
        cate1: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        cate2: [
          { required: true, message: "请选择正确的品名", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请选择正确的分值", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择正确的状态", trigger: "blur" },
        ],
        host: [{ required: true, message: "请选择数据来源", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCate1List();
  },
  methods: {
    handleCurrentChange(val) {
      this.getTableData();
    },
    showDialog(row) {
      console.log(row);
      this.getCate2List(row.cate2.cate1.id);
      this.record = JSON.parse(JSON.stringify(row));
      this.record.cate1_id = row.cate2.cate1.id;
      this.updateVisable = true;
    },
    getTableData() {
      request
        .get("/admin/record/search", {
          params: {
            cate2_id: this.price.cate2_id,
            score: this.price.score,
            good: this.good,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.tableList = res.data.list;
          this.total = res.data.total;
        });
    },
    searchCert() {
      console.log(this.cert)
      request
        .get("/admin/record/searchCert", {
          params: {
            cert: this.cert,
          },
        })
        .then((res) => {
          this.tableList = res.data;
        });
    },
    getCate1List() {
      //发起Cate1请求
      console.log("发起请求");
      request.get("/common/getCate1List", {}).then((res) => {
        this.cate1List = res.data;
      });
    },
    getCate2List(cate1id) {
      request
        .get("/common/getCate2List", {
          params: {
            id: cate1id,
          },
        })
        .then((res) => {
          this.cate2List = res.data;
        });
    },
    getHostList() {
      //发起Cate1请求
      request.get("/common/getHostList", {}).then((res) => {
        this.hostList = res.data;
      });
    },
    updateRecord() {
      this.$refs.recordRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        this.record.user = null;
        this.record.host = null;
        this.record.cate2 = null;
        request.post("/admin/record/update", this.record).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getTableData();
            this.updateVisable = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    //删除
    del(id) {
      console.log(id);
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/record/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    god(id) {
      console.log(id);
      this.$confirm("是否设为精品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/record/good/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    showImg(cert){
      console.log('当前的编号：' + cert)
      request
        .get("/admin/img", {
          params: {
            cert: cert,
          },
        })
        .then((res) => {
          if (res.code == "0") {
            this.imgurl = '/api/files/getPcgsImg?fileName=' + res.data.img
            this.showImgVisable = true
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    }
  },
};
</script>

<style scoped>
</style>
