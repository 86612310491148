<template>
  <div>
    <el-card class="box-card">
      <el-form :model="pcgsimg" ref="pcgsimgRef" label-width="100px">
        <el-form-item label="PCGS编号" @blur="judge">
          <el-input v-model="pcgsimg.cert" style="width: 223px"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="judge()">查看</el-button>
        </el-form-item>
      </el-form>

      <el-dialog title="提示" :visible.sync="updateVisable" width="40%">
        <el-form
          :model="record"
          :rules="rules"
          ref="recordRef"
          label-width="100px"
        >
        <el-form-item label="图片">
            <el-image
      style="width: 600px; height: 400px"
      :src="imgurl"
      fit="fit"></el-image>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload
              ref="upload2"
              class="upload-demo"
              drag
              action="/api/files/uploadPcgsImg"
              :on-success="imgSuccess"
              :before-upload="beforeuploadImg"

            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传jpg/png文件，且不超过5MB
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateVisable = false">取 消</el-button>
          <el-button type="primary" @click="updateImg">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserInput",
  data() {
    return {
      pcgsimg: {},
      imgurl: '/api/file/getPcgsImg?fileName=null',
      updateVisable: false,
      rules: {
        name: [{ required: true, message: "请输入编号", trigger: "blur" }],
      },
    };
  },
  methods: {
    imgSuccess(res) {
      this.pcgsimg.img = res.msg;
    },
    reset() {
      this.$refs.upload2.clearFiles();
      this.pcgsimg = JSON.parse(JSON.stringify({}));
    },
    updateImg() {
      this.$refs.pcgsimgRef.validate((valid) => {
        if (!valid) return;
        //发起网络请求
        request.post("/admin/img", this.pcgsimg).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.reset();
            this.updateVisable = false
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    judge() {
      if (this.pcgsimg.cert == null) return;
      //发起网络请求
      request
        .get("/admin/img", {
          params: {
            cert: this.pcgsimg.cert,
          },
        })
        .then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.pcgsimg.img = res.data.img;
            this.pcgsimg.id = res.data.id
            this.imgurl = '/api/files/getPcgsImg?fileName=' + res.data.img
            this.updateVisable = true
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    beforeuploadImg(file) {
      const isLt3M = file.size / 1024 / 1024 < 5;

      if (!isLt3M) {
        this.$message({
          message: "图片大小不能超过5M!请重新选择!",
          type: "warning",
        });
        return false; //必须加上return false; 才能阻止
      }
    }
  },
};
</script>

<style scoped>
</style>
