<template>
  <div>
    <el-card class="box-card">
      
      <el-row :gutter="0">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="系列">
            <el-select
              v-model="price.cate1_id"
              placeholder="系列"
              @change="getCate2List"
            >
              <el-option
                v-for="item in cate1List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品名">
            <el-select v-model="price.cate2_id" placeholder="品名">
              <el-option
                v-for="item in cate2List"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分值">
            <el-select v-model="price.score" placeholder="请选择分值">
            <el-option label="45分" value="45"></el-option>
            <el-option label="40分" value="40"></el-option>
            <el-option label="68分" value="68"></el-option>
            <el-option label="67+分" value="67+"></el-option>
            <el-option label="67分" value="67"></el-option>
            <el-option label="66+分" value="66+"></el-option>
            <el-option label="66分" value="66"></el-option>
            <el-option label="65+分" value="65+"></el-option>
            <el-option label="65分" value="65"></el-option>
            <el-option label="64+分" value="64+"></el-option>
            <el-option label="64分" value="64"></el-option>
            <el-option label="63+分" value="63+"></el-option>
            <el-option label="63分" value="63"></el-option>
            <el-option label="62+分" value="62+"></el-option>
            <el-option label="62分" value="62"></el-option>
            <el-option label="61+分" value="61+"></el-option>
            <el-option label="61分" value="61"></el-option>
            <el-option label="60分" value="60"></el-option>
            <el-option label="58+分" value="58+"></el-option>
            <el-option label="58分" value="58"></el-option>
            <el-option label="55分" value="55"></el-option>
            <el-option label="53分" value="53"></el-option>
            <el-option label="50分" value="50"></el-option>
            <el-option label="35分" value="35"></el-option>
            <el-option label="30分" value="30"></el-option>
            <el-option label="91分" value="91"></el-option>
            <el-option label="92分" value="92"></el-option>
            <el-option label="93分" value="93"></el-option>
            <el-option label="94分" value="94"></el-option>
            <el-option label="95分" value="95"></el-option>
            <el-option label="97分" value="97"></el-option>
            <el-option label="98分" value="98"></el-option>
            <el-option label="UNC92分" value="UNC92"></el-option>
            <el-option label="UNC97分" value="UNC97"></el-option>
            <el-option label="UNC98分" value="UNC98"></el-option>
            <el-option label="sp40" value="sp40"></el-option>
            <el-option label="sp45" value="sp45"></el-option>
            <el-option label="sp50" value="sp50"></el-option>
            <el-option label="sp53" value="sp53"></el-option>
            <el-option label="sp55" value="sp55"></el-option>
            <el-option label="sp58" value="sp58"></el-option>
            <el-option label="sp58+" value="sp58+"></el-option>
            <el-option label="sp60" value="sp60"></el-option>
            <el-option label="sp60+" value="sp60+"></el-option>
            <el-option label="sp61" value="sp61"></el-option>
            <el-option label="sp61+" value="sp61+"></el-option>
            <el-option label="sp62" value="sp62"></el-option>
            <el-option label="sp62+" value="sp62+"></el-option>
            <el-option label="sp63" value="sp63"></el-option>
            <el-option label="sp63+" value="sp63+"></el-option>
            <el-option label="sp64" value="sp64"></el-option>
            <el-option label="sp64+" value="sp64+"></el-option>
            <el-option label="sp65" value="sp65"></el-option>
            <el-option label="sp65+" value="sp65+"></el-option>
            <el-option label="sp66" value="sp66"></el-option>
            <el-option label="sp66+" value="sp66+"></el-option>
            <el-option label="sp67" value="sp67"></el-option>
            <el-option label="sp67+" value="sp67+"></el-option>
            <el-option label="sp68" value="sp68"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getTableData">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="dialogVisible = true"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="avp" label="参考价"></el-table-column>
        <el-table-column prop="max" label="最高价"></el-table-column>
        <el-table-column prop="min" label="最低价"></el-table-column>
        <el-table-column prop="date_start" label="开始日期"></el-table-column>
        <el-table-column prop="date_end" label="结束日期"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="新增" :visible.sync="dialogVisible">
        <el-form :model="price" :rules="rules" ref="priceRef">
          <div
            v-show="chartFlag"
            id="chart1"
            :style="{ width: '400px', height: '350px' }"
          ></div>
          <el-form-item label="参考价" label-width="120px" prop="avp">
            <el-input v-model="price.avp" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="最高价" label-width="120px" prop="max">
            <el-input v-model="price.max" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="最低价" label-width="120px" prop="min">
            <el-input v-model="price.min" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="时间范围" label-width="120px">
            <el-date-picker
              v-model="price.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="success" @click="compute">一键计算</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="add">提 交</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AdminPrice",
  data() {
    return {
      cate1List: [],
      cate2List: [],
      priceArr: [],
      cate1: "",
      cate2: "",
      pagenum: 1,
      pagesize: 2,
      total: 0,
      tableData: [],
      dialogVisible: false,
      addCate2Visable: false,
      price: {},
      chartFlag: false,
      chartlist: [],
      rules: {
        name: [
          { required: true, message: "请输入系列", trigger: "blur" },
          { min: 1, max: 20, message: "长度在2到20之间", trigger: "blur" },
        ],
        avp: [{ required: true, message: "请选择正确的系列", trigger: "blur" }],
        max: [{ required: true, message: "请选择正确的品名", trigger: "blur" }],
        min: [{ required: true, message: "请选择正确的分值", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCate1List();
  },
  methods: {
    getCate1List() {
      //发起Cate1请求
      console.log("发起请求");
      request.get("/common/getCate1List", {}).then((res) => {
        this.cate1List = res.data;
      });
    },
    getCate2List() {
      request
        .get("/common/getCate2List", {
          params: {
            id: this.price.cate1_id,
          },
        })
        .then((res) => {
          this.cate2List = res.data;
        });
    },
    getTableData() {
      request
        .get("/admin/price", {
          params: {
            cate2_id: this.price.cate2_id,
            score: this.price.score,
          },
        })
        .then((res) => {
          this.tableData = res.data;
        });
    },
    resetForm() {
      //重置
      console.log("开始重置");
      this.price.avp = "";
      this.price.max = "";
      this.price.min = "";
      this.price.date_start = "";
      this.price.date_end = "";
      this.price.dateRange = [];
    },
    add() {
      this.$refs.priceRef.validate((valid) => {
        console.log(valid);
        if (!valid) return;
        //发起网络请求
        this.price.date_start = this.price.dateRange[0];
        this.price.date_end = this.price.dateRange[1];
        request.post("/admin/price", this.price).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getTableData();
            this.resetForm();
            this.dialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    initChart(name) {
      var myChart = this.$echarts.init(document.getElementById(name));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "分布图",
        },
        xAxis: {
          name: "价格",
        },
        yAxis: {
          name: "序号",
        },
        series: [
          {
            symbolSize: 10,
            type: "scatter",
            data: this.chartlist,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    compute() {
      request
        .get("/admin/price/compute", {
          params: {
            cate2_id: this.price.cate2_id,
            score: this.price.score,
            date_start: this.price.dateRange[0],
            date_end: this.price.dateRange[1],
          },
        })
        .then((res) => {
          if (res.code == "0") {
            console.log(res.data);
            let dic = res.data;
            dic.cate1_id = this.price.cate1_id;
            dic.cate2_id = this.price.cate2_id;
            dic.score = this.price.score;
            dic.dateRange = this.price.dateRange;
            this.price = JSON.parse(JSON.stringify(dic));
            this.chartlist = dic.list;

            this.chartFlag = true;
            this.initChart("chart1");
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    del(id) {
      console.log(id);
      this.$confirm("是否删除该系列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request.delete("/admin/price/" + id, {}).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getTableData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style scoped>
</style>
