<template>
  <el-container class="home-container">
    <el-header>
      <div>
        <span>PCGS管理系统</span>
      </div>
      <div>
        <span class="nick">当前用户：{{ nickname }}</span>
        <el-dropdown style="margin: 0px 20px" @command="handleCommand">
          <el-button type="text" >
            切换管理<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-plus" command="1">铜钱</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="text" @click="logout">退出</el-button>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          unique-opened
          router
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-data"></i>
              <span>价格管理</span>
            </template>
            <el-menu-item index="/admin/price">价格列表</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-document-copy"></i>
              <span>输入记录管理</span>
            </template>
            <el-menu-item index="/admin/verify">审核</el-menu-item>
            <el-menu-item index="/admin/record">查询记录</el-menu-item>
            <el-menu-item index="/admin/excel">导出Excel</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-s-promotion"></i>
              <span>综合管理</span>
            </template>
            <el-menu-item index="/admin/score">存量管理</el-menu-item>
            <el-menu-item index="/admin/cate1">系列管理</el-menu-item>
            <el-menu-item index="/admin/cate2">品名管理</el-menu-item>
            <el-menu-item index="/admin/host">来源管理</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-picture"></i>
              <span>图片管理</span>
            </template>
            <el-menu-item index="/admin/img">编号修改</el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-document-copy"></i>
              <span>文章管理</span>
            </template>
            <el-menu-item index="/admin/rules">评级规则</el-menu-item>
            <el-menu-item index="/admin/template">品相参考</el-menu-item>
          </el-submenu>
          <el-submenu index="6" v-if="auth >= 10">
            <template slot="title">
              <i class="el-icon-s-check"></i>
              <span>会员设置</span>
            </template>
            <!--<el-menu-item index="/admin/code">生成优惠码</el-menu-item>-->
            <el-menu-item index="/admin/vip">会员管理</el-menu-item>
            <el-menu-item index="/admin/order">所有订单</el-menu-item>
          </el-submenu>
          <el-submenu index="7" v-if="auth >= 10">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>高级设置</span>
            </template>
            <el-menu-item index="/admin/users">员工管理</el-menu-item>
            <el-menu-item index="/admin/tongji">收费统计</el-menu-item>
          </el-submenu>
          <el-menu-item index="/user/input">切换输入端</el-menu-item>
          <!--<el-menu-item index="/admin/log">实时日志</el-menu-item> -->
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      nickname: "",
      auth: "",
    };
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    getInit() {
      this.nickname = sessionStorage.getItem("nickname");
      this.auth = sessionStorage.getItem("auth");
    },
    handleCommand(command) {
        if(command == 1) this.$router.push('/copper')
      }
  },
  created() {
    this.getInit();
  },
};
</script>

<style scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
}
.el-aside {
  background-color: white;
}
.el-main {
  background-color: #eeeeee;
}

.nick {
  font-size: 18px;
  margin: 3px 12px;
}
</style>
