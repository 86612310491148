<template>
  <div>
    <el-card class="box-card">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column prop="name" label="月份">
        </el-table-column>
        <el-table-column prop="cnt" label="输入数量">
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserStat",
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      console.log("初始化");
      request.get("/user/info/stat", {}).then((res) => {
        this.tableData = res.data;
      });
    },
  },
};
</script>

<style scoped>
</style>
