<template>
  <el-container class="home-container">
    <el-header>
      <div>
        <span>铜钱后台管理</span>
      </div>
      <div>
        <span class="nick">当前用户：{{ nickname }}</span>
        <el-dropdown style="margin: 0px 20px" @command="handleCommand">
          <el-button type="text" >
            切换管理<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-plus" command="0">银元</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button @click="logout" size="medium" round>退出</el-button>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          unique-opened
          router
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-unfold"></i>
              <span>铜钱输入管理</span>
            </template>
            <el-menu-item index="/copper/verify">输入审核</el-menu-item>
            <el-menu-item index="/copper/record">查询记录</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>综合管理</span>
            </template>
            <el-menu-item index="/copper/category">分类管理</el-menu-item>
            <el-menu-item index="/copper/host">来源管理</el-menu-item>
          </el-submenu>
          <el-menu-item index="/user/input">切换输入端</el-menu-item>
          <!--<el-menu-item index="/admin/log">实时日志</el-menu-item> -->
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      nickname: "",
      auth: "",
    };
  },
  methods: {
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    getInit() {
      this.nickname = sessionStorage.getItem("nickname");
      this.auth = sessionStorage.getItem("auth");
    },
    handleCommand(command) {
        if(command == 0) this.$router.push('/admin')
      }
  },
  created() {
    this.getInit()
  },
};
</script>

<style scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
}
.el-aside {
  background-color: white;
}
.el-main {
  background-color: #eeeeee;
}

.nick {
  font-size: 18px;
  margin: 3px 12px;
}
</style>
