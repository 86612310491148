<template>
  <div>
    <el-card class="box-card">
      <div class="log-main" v-loading="flag">
        <div class="log-item" v-for="item in logs" :key="item">{{ item }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "AdminLog",
  data() {
    return {
      logs: [],
      flag: true,
    };
  },
  created() {
    let timer = setInterval(() => {
      request.get("/admin/log", {}).then((res) => {
        if (res.code == "0") {
          this.logs = res.data;
          if(this.flag == true) this.flag = false;
        }
      });
    }, 5000);
  },
  methods: {},
};
</script>

<style scoped>
.log-main {
  width: 100%;
  height: calc(100vh - 140px);
  overflow-y: scroll;
}
.log-item {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.808);
}
</style>
